import { XMarkIcon } from "@heroicons/react/24/outline";
import toast from "react-hot-toast";

export const WarningToast = ({ toastId, message }: { toastId: string, message: string | JSX.Element }) => {
    return (
        <div className="flex items-center justify-between w-[28rem]">
            <div className="text-text-primary text-center">{message}</div>
            <div className="cursor-pointer w-fit" onClick={() => toast.dismiss(toastId)}>
                <XMarkIcon width="16" height="16" className="text-slate-400"/>
            </div>
        </div>
    );
};
