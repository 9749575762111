import { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Button from "src/components/button/Button";
import { ButtonTypes } from "src/components/button/types";
import Layout from "src/components/layout/Layout";
import TopBar from "src/components/layout/TopBar";
import PageLoader from "src/components/loaders/PageLoader";
import Modal from "src/components/Modal/Modal";
import { notify } from "src/components/Toaster";
import { getSyncJobValidationError } from "src/features/dataModelSync/syncJobs/getSyncJobValidationError";
import { SyncJobForm } from "src/features/dataModelSync/syncJobs/SyncJobForm";
import { SyncJob, SyncTarget } from "src/features/dataModelSync/types";
import { SQLDialect } from "src/features/projects/IProject";
import { selectActiveAccountId } from "src/infrastructure/state/slices/activeAccountSlice";
import { useRunJobMutation, useCreateJobMutation, useLazyGetTargetsQuery, useCreateTargetMutation } from "src/services/actions/actions";
import { extractErrorMessage } from "src/services/api";

const emptySyncJob: SyncJob = {
    id: 0,
    name: '',
    description: '',
    targetId: null,
    configuration: {
        accountId: 0,
        targetBranch: '',
        targetDirectory: '/dbt_sync',
        targetType: 'github',
        sqlDialect: SQLDialect.SNOWFLAKE,
        automaticallySyncDataModel: false,
        eunoProjectId: 0,
        modelSyncType: 'all',
        metricSyncType: 'all',
        selectedModels: [],
        selectedModelTags: [],
        pushType: "pull_request",
        dbtProjectName: '',
    }
};

export const CreateSyncJobPage = () => {
    const [syncTarget, setSyncTarget] = useState<SyncTarget | null>(null);
    const accountId = useSelector(selectActiveAccountId);
    const [syncJob, setSyncJob] = useState<SyncJob | null>({ ...emptySyncJob, configuration: { ...emptySyncJob.configuration, accountId } });
    const navigate = useNavigate();
    const [runSyncJob, { isLoading: isLoadingRunSyncJob }] = useRunJobMutation();
    const [createSyncJob, { isLoading: isLoadingCreateSyncJob }] = useCreateJobMutation();
    const isLoading = isLoadingRunSyncJob || isLoadingCreateSyncJob;
    const [showCancelModal, setShowCancelModal] = useState(false);
    const [getTargets] = useLazyGetTargetsQuery();
    const [createSyncTarget] = useCreateTargetMutation();

    const onCancel = () => {
        navigate(-1);
    };

    const onSave = async (withRun: boolean) => {
        if (!syncJob) {
            return;
        }
        const validateError = getSyncJobValidationError(syncJob, syncTarget);
        if (validateError) {
            notify(validateError, 'error');
            return;
        }
        let jobId: number | null = null;
        try {
            let targetId: number | null = null;
            if (syncTarget) {
                const existingTargets = await getTargets({ accountId }).unwrap();
                const matchingTarget = existingTargets.find(t => t.configuration.targetRepositoryUrl === syncTarget?.configuration.targetRepositoryUrl);
                if (matchingTarget) {
                    targetId = matchingTarget.id || null;
                }
                else {
                    targetId = await createSyncTarget({ accountId, target: { ...syncTarget } }).unwrap();
                }
            }
            jobId = await createSyncJob({ accountId, job: { ...syncJob, targetId } }).unwrap();
            notify('Sync job created successfully', 'success');
            if (withRun && jobId) {
                try {
                    await runSyncJob({ accountId, jobId }).unwrap();
                    notify('Sync run started', 'success');
                }
                catch (e) {
                    setTimeout(() => {
                        notify(`Failed to start sync run: ${extractErrorMessage(e).message}`, 'error');
                    }, 1000);
                    console.error(e);
                }
            }
            navigate('/data-model-sync?tab=jobs');
        }
        catch (e) {
            notify(`Failed to update sync job: ${extractErrorMessage(e).message}`, 'error');
            console.error(e);
        }
    };


    if (!syncJob) {
        return <PageLoader />;
    }

    return (
        <Layout>
            <div className="flex flex-col h-full">
                <TopBar>
                    <div className="text-lg">
                        Create sync job
                    </div>
                </TopBar>
                <SyncJobForm syncJob={syncJob} syncTarget={syncTarget} setSyncJob={setSyncJob} setSyncTarget={setSyncTarget} />
                <div className="bg-white w-full p-4 flex gap-4 border-t border-border sticky bottom-0">
                    <Button type={ButtonTypes.secondary} className="w-24" text="Cancel" onClick={() => setShowCancelModal(true)} />
                    <Button isLoading={isLoading} type={ButtonTypes.primary} className="w-24 ml-auto" text="Save" onClick={() => onSave(false)} />
                </div>
            </div>
            <Modal
                isOpen={showCancelModal}
                onClose={() => setShowCancelModal(false)}
                title="Leave without saving?"
                buttons={[{ text: "Exit", onClick: onCancel, type: ButtonTypes.secondary }, { text: "Continue creating", onClick: () => setShowCancelModal(false), type: ButtonTypes.primary }]}>
                <div className="text-text-primary">You have unsaved changes. If you leave this page, any changes you have made will be lost.</div>
            </Modal>
        </Layout>
    );
};
