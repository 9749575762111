import { useUser } from '@descope/react-sdk';
import { useSelector } from 'react-redux';
import { selectActiveAccountId } from 'src/infrastructure/state/slices/activeAccountSlice';

export const useIsEunoUser = () => {
    const eunoEmailSuffix = '@euno.ai';
    const eunoAccountIds = [1, 4];
    const { user } = useUser();
    const isEunoUser = user?.email?.endsWith(eunoEmailSuffix);
    const isLoaded = !!user?.email;
    const accountId = useSelector(selectActiveAccountId);
    const isEunoAccount = eunoAccountIds.includes(accountId);
    return [isEunoUser, isLoaded, isEunoAccount];
};