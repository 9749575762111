import { useEffect, useMemo, useRef } from "react";
import { useSelector } from "react-redux";
import { Operation, OperationStatus, OperationType } from "src/features/operations/Operation";
import { selectActiveAccountId } from "src/infrastructure/state/slices/activeAccountSlice";
import { useGetAccountOperationQuery, useGetAccountOperationsQuery } from "src/services/operations";

export const useIsGeneratingDiff = (changeId: number): boolean => {
    const [operation] = useGetChangeOperation(changeId, 'generate_git_diff');
    return operation?.status === OperationStatus.Running || operation?.status === OperationStatus.Pending;
};

export const useIsCreatingPullRequest = (changeId: number): boolean => {
    const [operation] = useGetChangeOperation(changeId, 'create_pull_request');
    return operation?.status === OperationStatus.Running || operation?.status === OperationStatus.Pending;
};

export const useGetChangeOperation = (changeId: number, type: OperationType): [Operation | null, boolean] => {
    const accountId = useSelector(selectActiveAccountId);
    const getOperations = useGetAccountOperationsQuery({ accountId, page: 1, pageSize: 100 });
    const operations = useMemo(() => getOperations.data?.items || [], [getOperations.data]);
    const operationId = operations.find((op) => op.name === type && op.change_id === changeId)?.id;
    const getOperation = useGetAccountOperationQuery({ accountId, operationId: operationId || 0 }, { skip: !operationId });
    const lastFetchTime = useRef<number>(0);

    useEffect(() => {
        const isRunning = getOperation.data?.status === OperationStatus.Running || getOperation.data?.status === OperationStatus.Pending;
        if (isRunning) {
            const now = Date.now();
            lastFetchTime.current = now;
            const timeout = setTimeout(() => {
                if (lastFetchTime.current === now) {
                    getOperation.refetch();
                }
            }, 5000);
            return () => clearTimeout(timeout);
        }
    }, [getOperation]);

    return [getOperation.data || null, getOperation.isFetching || getOperations.isFetching];
};