import { TableColumnProps } from "src/components/Table/types";
import { DataSyncRunStatusLabel } from "src/features/dataModelSync/syncRuns/DataSyncRunStatusLabel";
import { SyncRunsActionsColumn } from "src/features/dataModelSync/syncRuns/SyncRunsActionsColumn";
import { SyncRun } from "src/features/dataModelSync/types";
import { utcToLocal, dateFormats } from "src/infrastructure/dateUtilities";

export const syncRunsTableColumns: TableColumnProps<SyncRun>[] = [
    {
        name: 'TIMESTAMP',
        selector: (row: SyncRun) => (
            <span className="font-medium">
                {utcToLocal(`${row.createdAt}`, dateFormats.monthsDaysHoursAndMinutes)}
            </span>
        )
    },
    {
        name: 'JOB',
        selector: (row: SyncRun) => (
            <span className="font-medium">
                {row.title}
            </span>
        ),
        width: '300px'
    },
    {
        name: 'TRIGGER',
        selector: (row: SyncRun) => (
            <span className="font-medium">
                {row.triggerType}
            </span>
        )
    },
    {
        name: 'TRIGGERED BY',
        selector: (row: SyncRun) => (
            <span className="font-medium">
                {row.createdBy}
            </span>
        )
    },
    {
        name: 'STATUS',
        selector: (row: SyncRun) => (
            <span className="font-medium">
                <DataSyncRunStatusLabel status={row.state} />
            </span>
        )
    },
    {
        name: 'ACTION',
        selector: (row: SyncRun) => (
            <SyncRunsActionsColumn run={row} />
        ),
        width: '300px'
    }
];
