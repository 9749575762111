import { PullRequestIcon } from '../../../assets/images/icons/DelphiIcons';
import Modal from '../../../components/Modal/Modal';
import { notify } from '../../../components/Toaster';
import { ButtonTypes } from '../../../components/button/types';
import { extractErrorMessage } from '../../../services/api';
import { useCreatePullRequestMutation } from '../../../services/changes/changes';
import { IChange } from '../IChange';
import ConnectedBulletPoints from '../../../components/ConnectedBulletPoints';
import { events, trackEvent } from '../../../infrastructure/analytics';

interface CreatePullRequestModalProps {
  change: IChange;
  onClose: () => void;
  isOpen: boolean;
}

export const CreatePullRequestModal = ({ change, onClose, isOpen }: CreatePullRequestModalProps) => {
  const [createPullRequestMutation, { isLoading: isLoadingCreatePullRequest }] = useCreatePullRequestMutation();
  const bulletContents = ['Create branch', 'Commit change', 'Submit pull request'];

  const createPullRequest = async () => {
    try {
      if (!change.id) throw new Error('Change ID is missing');
      trackEvent(events.dataModelPRCreated, { change_type: change.changeType });
      await createPullRequestMutation({ projectId: change.projectId, changeId: change.id }).unwrap();
      notify('Creating pull request', 'success');
      onClose();
    } catch (e) {
      notify(`Failed to create pull request: ${extractErrorMessage(e).message}`, 'error');
    }
  };

  return (
    <Modal
      buttons={[{ type: ButtonTypes.secondary, text: 'Close', onClick: onClose }, { type: ButtonTypes.primary, text: 'Create pull request', onClick: createPullRequest, isLoading: isLoadingCreatePullRequest }]}
      title="Create pull request"
      onClose={onClose}
      isOpen={isOpen}>
      <div>
        <PullRequestIcon fill="#334155" height={30} width={30} className="ml-auto mr-auto" />
      </div>
      <div className="mt-4 flex flex-col items-center gap-4 rounded-lg border border-slate-200 bg-surface-light p-6 text-text-primary">
        <div className="font-medium">Euno will execute the following actions on your behalf:</div>
        <ConnectedBulletPoints
          bulletPoints={bulletContents.map((content) => ({
            content,
            textColor: 'text-primary',
            bulletColor: 'bg-text-primary',
            lineColor: 'bg-slate-200'
          }))}
        />
      </div>
    </Modal>
  );
};
