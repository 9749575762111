import { NewModelChangeData } from '../../IChange';
import { TagsForm } from '../../formSections/TagsForm';
import { MetaForm } from '../../formSections/MetaForm';
import { DescriptionForm } from '../../formSections/DescriptionForm';
import { NameForm } from '../../formSections/NameForm';
import { useState } from 'react';
import { NewModelCode } from './NewModelCode';
import { NewModelSubnodeTab } from './NewModelSubnodeTab';
import { ModelMaterializationForm } from './ModelMaterializationForm';
import { ModelChangeImplications } from '../../ModelChangeImplications';
import { NodeType } from 'src/features/models/discover/INode';

type NewModelFormProps = {
  changeData: NewModelChangeData;
  setChangeData: (changeData: NewModelChangeData) => void;
  sourceName: string;
  sourceUtl: string;
  sourceType: NodeType;
}

type Tab = 'Model' | 'Columns' | 'Semantics';

export const NewModelForm = ({ changeData, setChangeData, sourceName, sourceType }: NewModelFormProps) => {
  const [tab, setTab] = useState<Tab>('Model');
  return (
    <div className="mt-4 h-[450px] flex flex-col">
      <div className="flex items-center gap-2">
        <div className="text-secondary text-sm font-medium w-24">Model changes</div>
        <ModelChangeImplications
          targetNodeName={changeData.newModelName}
          sourceName={sourceName}
          sourceType={sourceType}
        />
      </div>
      <div className="mt-4 rounded bg-slate-100 border border-slate-200 flex-1 flex flex-col">
        <Tabs tab={tab} setTab={setTab} />
        {tab === 'Model' && <ModelTab changeData={changeData} setChangeData={setChangeData} />}
        {tab === 'Columns' && <NewModelSubnodeTab type='Columns' changeData={changeData} setChangeData={setChangeData} />}
        {tab === 'Semantics' && <NewModelSubnodeTab type='Semantics' changeData={changeData} setChangeData={setChangeData} />}
      </div>
    </div>
  );
};

const Tabs = ({ tab, setTab }: { tab: Tab, setTab: (tab: Tab) => void }) => {
  return (
    <div className="mt-2 rounded bg-slate-200 flex mx-auto w-fit border text-secondary text-sm mb-4">
      {
        ['Model', 'Columns', 'Semantics'].map((tabName) => (
          <div
            key={tabName}
            onClick={() => setTab(tabName as 'Model' | 'Columns' | 'Semantics')}
            className={`flex-1 py-1 px-2 text-center cursor-pointer rounded ${tab === tabName ? 'bg-white' : ''}`}
          >
            {tabName}
          </div>
        ))
      }
    </div>
  );
};

type ModelTabProps = {
  changeData: NewModelChangeData;
  setChangeData: (changeData: NewModelChangeData) => void;
}

const ModelTab = ({ changeData, setChangeData }: ModelTabProps) => {
  return (
    <div className="flex flex-col gap-2 p-2">
      <div className="flex items-center">
        <NameForm labelClassName='w-40' label="Model" name={changeData.newModelName} setName={newModelName => setChangeData({ ...changeData, newModelName })} />
        <NewModelCode changeData={changeData} />
      </div>
      <DescriptionForm labelClassName='w-40' label="Model" description={changeData.description} setDescription={description => setChangeData({ ...changeData, description })} />
      <TagsForm labelClassName='w-40' label="Model" tags={changeData.tags} setTags={tags => setChangeData({ ...changeData, tags })} />
      <MetaForm labelClassName='w-40' label="Model" meta={changeData.meta} setMeta={meta => setChangeData({ ...changeData, meta })} />
      <ModelMaterializationForm labelClassName='w-40' materialization={changeData.materialization} setMaterialization={materialization => setChangeData({ ...changeData, materialization })} />
    </div>
  );
};