import { ModelIcon } from '../../../../assets/images/icons/DelphiIcons';
import { IChange, NewMeasureChangeData } from '../../IChange';
import { MetaSection } from '../MetaSection';
import { TagsSection } from '../TagsSection';
import { NodeIcon } from '../../../models/discover/NodeIcon';
import { NodeType, SubnodeType } from '../../../models/discover/INode';
import { ModelChangeSection } from '../ModelChangeSection';
import { ModelChangeImplications } from '../../ModelChangeImplications';

export const ChangeSection = ({ change }: { change: IChange }) => {
  const newMeasureChange = change.changeData as NewMeasureChangeData;
  return (
    <div className="flex flex-col gap-8">
      <div>
        <div className="flex gap-2">
          <div className="bg-white p-2 rounded-lg border border-slate-200 h-fit w-fit mt-0.5">
            <NodeIcon type={NodeType.DataModel} subnodeType={SubnodeType.Measure} iconSize={20} />
          </div>
          <div className="flex flex-col">
            <div className="text-lg text-text-primary">{newMeasureChange.newMeasureName}</div>
            <div className="flex items-center text-sm text-tertiary">
              Measure in <ModelIcon width="14" height="14" fill="#64748B" className="ml-2 mr-1" />
              <span className="font-medium">{newMeasureChange.modelId}</span>
            </div>
          </div>
        </div>
        <div className="mt-4 text-text-primary">{newMeasureChange.description}</div>
      </div>
      <div className="flex gap-4">
        <div className="rounded-lg border border-slate-200 bg-white p-4 text-secondary w-[30%]">
          <TagsSection tags={newMeasureChange.tags} />
          <MetaSection meta={newMeasureChange.meta} />
        </div>
        <ModelChangeSection>
          <ModelChangeImplications targetUtl={change.targetUtl} sourceUtl={change.sourceUtl} targetNodeName={newMeasureChange.modelId} targetSubnodeName={newMeasureChange.newMeasureName} targetSubnodeType={SubnodeType.Measure} sourceName={change.sourceName} sourceType={change.sourceType} sourceSubnodeName={newMeasureChange.nameInDataApplication} sourceSubnodeType={SubnodeType.Measure} />
        </ModelChangeSection>
      </div>
    </div>
  );
};