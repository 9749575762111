type TabsProps = {
    items: TabItem[];
    className?: string;
}

type TabItem = {
    onClick: () => void;
    text: string;
    isActive: boolean;
}

export const Tabs = ({ items, className }: TabsProps) => {
    return (
        <div className={`flex gap-1 text-text-primary bg-white ${className}`}>
            {
                items.map((item, i) => <TabItem key={i} {...item} />)
            }
        </div>
    );
};

const TabItem = ({ onClick, isActive, text }: TabItem) => {
    
    return (
        <div className={`rounded px-2 py-1 hover:bg-lilac-100 cursor-pointer ${isActive && 'bg-lilac-100'}`} onClick={onClick}>
            {text}
        </div>
    );
};