import { useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { ChevronLeftIcon } from "@heroicons/react/24/solid";
import { useNavigate, useParams } from "react-router-dom";
import Layout from "src/components/layout/Layout";
import TopBar from "src/components/layout/TopBar";
import { selectActiveAccountId } from "src/infrastructure/state/slices/activeAccountSlice";
import {
  useCrawlIntegrationMutation,
  useGetGenericIntegrationQuery,
  useUpdateGenericIntegrationMutation
} from "src/services/integrations/integrations";
import { SOURCE_ICONS } from "src/features/sources/Consts";
import { GenericIntegration } from "src/services/integrations/types";
import PageLoader from "src/components/loaders/PageLoader";
import { dateFormats, utcToLocal } from "src/infrastructure/dateUtilities";
import { SourceRunStatus } from "./SourceRunStatus";
import Button from "src/components/button/Button";
import { ButtonTypes } from "src/components/button/types";
import { PlayIcon, SpinnerIcon, PauseIcon, CogIcon } from "src/assets/images/icons/DelphiIcons";
import { HealthLabel, HealthType } from "src/components/HealthLabel";
import SourceOperations from "src/features/sources/details/SourceOperations";
import { notify } from "src/components/Toaster";
import { extractErrorMessage } from "src/services/api";
import { IntegrationInfoModal } from "src/features/sources/IntegrationInfoModal";


const SourceDetails = () => {
  const navigate = useNavigate();
  const accountId = useSelector(selectActiveAccountId);
  const integrationId = useParams().id || '';
  const [activeStatusLoading, setActiveStatusLoading] = useState(false);
  const [runLoading, setRunLoading] = useState(false);
  const [runUrl, setRunUrl] = useState('');
  const [crawlIntegration] = useCrawlIntegrationMutation();
  const [updateIntegration] = useUpdateGenericIntegrationMutation();
  const getIntegration = useGetGenericIntegrationQuery({ accountId, integrationId });
  const integration = getIntegration.data as GenericIntegration;
  const ActivateButtonIcon = useMemo(() => {
    if (activeStatusLoading) {
      return (<SpinnerIcon className='animate-spin' />);
    } else if (integration?.active) {
      return (<PauseIcon width="16" height="16" />);
    } else {
      return (<PlayIcon width="16" height="16" />);
    }
  }, [activeStatusLoading, integration]);

  if (getIntegration.isLoading) {
    return (<PageLoader />);
  }

  const activate = async () => {
    setActiveStatusLoading(true);
    try {
      await updateIntegration({
        accountId,
        integrationId: integration.id,
        integrationName: integration.name,
        integrationType: integration.integration_type,
        active: !integration.active
      }).unwrap();
    } catch (e) {
      const errorMessage = `Failed to pause/resume integration: ${extractErrorMessage(e).message}`;
      notify(errorMessage, 'error');
      console.error(errorMessage);
    }
    setActiveStatusLoading(false);
  };
  const run = async () => {
    if (integration.integration_type === 'dbt_core') {
      setRunUrl(`/accounts/${accountId}/integrations/${integration.id}/run`);
    } else {
      setRunLoading(true);
      try {
        await crawlIntegration({ accountId, integrationId: integration.id }).unwrap();
      } catch (e) {
        const errorMessage = `Failed to run integration: ${extractErrorMessage(e).message}`;
        notify(errorMessage, 'error');
        console.error(errorMessage);
      }
      setRunLoading(false);
    }
  };
  const SourceIcon = SOURCE_ICONS[integration.integration_type];
  return (
    <Layout>
      <TopBar />
      <div className="bg-white">
        <div onClick={() => navigate('/sources')} className="cursor-pointer bg-white flex items-center font-semibold mt-8 ml-8 gap-1">
          <ChevronLeftIcon width="16" height="16" />
          Back to sources
        </div>
        <div className="flex flex-row bg-white border-b px-10 pt-8 pb-4">
          <SourceIcon width="48" height="48" className="text-black mr-6" fill="#FF694A" />
          <div className="flex flex-col w-full">
            <div className="flex flex-row justify-between order-b border-b">
              <div>
                <div className="flex flex-row text-2xl">
                  <div className="mr-1">{integration.name}</div>
                  <SourceRunStatus active={integration.active} loading={activeStatusLoading} />
                </div>
                <div className="text-tertiary mt-1">Enabled by {integration.created_by} on {utcToLocal(integration.created_at.toLocaleString(), dateFormats.monthsDaysHoursAndMinutes)}</div>
                <div className="text-tertiary mb-3">Updated at {utcToLocal(integration.last_updated_at.toLocaleString(), dateFormats.monthsDaysHoursAndMinutes)} by {integration.last_updated_by}</div>
              </div>
              <div className="flex text-base self-center">
                <Button
                  className="w-24 !text-base"
                  type={ButtonTypes.secondary}
                  text={integration.active ? "Pause" : "Resume"}
                  icon={ActivateButtonIcon}
                  onClick={activate}
                />
                <Button
                  className={`ml-4 w-24 !text-base ${runLoading ? 'opacity-50' : ''}`}
                  type={ButtonTypes.primary}
                  text="Run"
                  onClick={run}
                />
              </div>
            </div>
            <div className="flex flex-row justify-between mt-4">
              <div className="flex flex-row items-baseline">
                <HealthLabel health={integration.health as HealthType} />
                <div className="ml-8">
                  <span className="text-tertiary mr-1">ID</span>
                  {integration.id}
                </div>
              </div>
              <Button
                className="w-24 !text-base"
                type={ButtonTypes.secondary}
                text="Edit"
                icon={<CogIcon width="16" height="16" />}
                onClick={() => navigate(`/sources/connect/${integration.integration_type}/${integration.id}`)}
              />
            </div>
          </div>
        </div>
        <SourceOperations accountId={accountId} integrationId={integrationId} />
        <IntegrationInfoModal
          info={runUrl}
          modalTitle="Run URL"
          infoTitle="Use this url to trigger your run"
          label="Your run url"
          onClose={() => setRunUrl('')}
        />
      </div>
    </Layout>
  );
};

export default SourceDetails;
