import { IChange, NewModelChangeData } from '../../IChange';
import { MetaSection } from '../MetaSection';
import { TagsSection } from '../TagsSection';
import { NodeIcon } from '../../../models/discover/NodeIcon';
import { NodeType } from '../../../models/discover/INode';
import { BriefcaseIcon } from '@heroicons/react/24/outline';
import { useState } from 'react';
import { NewModelSubnodeTab } from '../../changeForms/newModel/NewModelSubnodeTab';
import { ModelChangeSection } from '../ModelChangeSection';
import { ModelChangeImplications } from '../../ModelChangeImplications';


export const NewModelChangeSection = ({ change }: { change: IChange }) => {
  const newModelChange = change.changeData as NewModelChangeData;
  return (
    <div className="flex flex-col flex-1 gap-8">
      <div className="flex justify-between gap-10">
        <div>
          <div className="flex gap-2">
            <div className="bg-white p-2 rounded-lg border border-slate-200 h-fit w-fit mt-0.5">
              <NodeIcon type={NodeType.DataModel} iconSize={20} />
            </div>
            <div className="flex flex-col">
              <div className="text-lg text-text-primary">{newModelChange.newModelName}</div>
              <div className="flex items-center text-sm text-tertiary gap-1">
                model in <BriefcaseIcon width="14" height="14" />
                <span className="font-medium">{newModelChange.projectName}</span>
              </div>
            </div>
          </div>
          <div className="mt-4 text-text-primary">{newModelChange.description}</div>
        </div>
      </div>
      <div className="flex gap-4">
        <div className="rounded-lg border border-slate-200 bg-white p-4 text-secondary w-[30%]">
          <TagsSection tags={newModelChange.tags} />
          <MetaSection meta={newModelChange.meta} />
        </div>
        <ModelChangeSection>
          <ModelChangeImplications targetUtl={change.targetUtl} sourceUtl={change.sourceUtl} targetNodeName={newModelChange.newModelName} sourceName={change.sourceName} sourceType={change.sourceType} />
        </ModelChangeSection>
      </div>
      <Tabs changeData={newModelChange} />
    </div>
  );
};

const Tabs = ({ changeData }: { changeData: NewModelChangeData }) => {
  const [tab, setTab] = useState<'Columns' | 'Semantics'>('Columns');
  return (
    <div className="bg-white rounded-lg border border-slate-200 flex flex-col flex-1">
      <div className="mt-4 rounded bg-slate-200 flex mx-auto w-fit border text-secondary text-sm mb-4">
        {
          ['Columns', 'Semantics'].map((tabName) => (
            <div
              key={tabName}
              onClick={() => setTab(tabName as 'Columns' | 'Semantics')}
              className={`flex-1 py-1 px-2 text-center cursor-pointer rounded ${tab === tabName ? 'bg-white' : ''}`}
            >
              {tabName}
            </div>
          ))
        }
      </div>
      <div className="bg-slate-100 rounded-b-lg flex-1 flex flex-col">
        <NewModelSubnodeTab changeData={changeData} type={tab} setChangeData={() => { }} disabled />
      </div>
    </div>
  );
};