import { useSelector } from 'react-redux';
import { selectActiveAccountId } from '../../infrastructure/state/slices/activeAccountSlice';
import { useGetOperationLogsQuery } from '../../services/operations';
import { ButtonTypes } from '../../components/button/types';
import OperationStatusLabel from '../operations/OperationStatusLabel';
import Modal from '../../components/Modal/Modal';
import { Operation } from '../operations/Operation';
import { dateFormats, utcToLocal } from '../../infrastructure/dateUtilities';

interface LogsModalProps {
  isOpen: boolean;
  operation: Operation | null;
  onClose: () => void;
}

export const LogsModalContent = ({ operation }: { operation: Operation | null }) => {
  const accountId = useSelector(selectActiveAccountId);
  const logs =
    useGetOperationLogsQuery({ accountId, operationId: operation?.id || 0 }, { skip: !operation || !accountId })
      ?.data || '';
  return (
    <>
      <div className="flex items-center justify-between">
        <div className="text-text-primary">
          {operation?.name}
          <span className="ml-2 text-sm text-slate-500">{operation?.start_time ? utcToLocal(operation?.start_time, dateFormats.monthsDaysHoursAndMinutes) : ''}</span>
        </div>
        <div>{operation && <OperationStatusLabel status={operation.status} />}</div>
      </div>
      <div className="mt-4 max-h-64 overflow-auto whitespace-pre-line rounded-lg border border-slate-200 bg-surface-light p-2 text-sm text-text-primary	">
        {logs || 'loading..'}
      </div>
    </>
  );
};

export const LogsModal = ({ isOpen, operation, onClose }: LogsModalProps) => {
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title="View Operation Logs"
      buttons={[{ type: ButtonTypes.primary, text: 'Done', onClick: onClose }]}
      maxWidth="max-w-2xl"
    >
      <LogsModalContent operation={operation} />
    </Modal>
  );
};
