import { ChangeEvent, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from 'react-router-dom';
import Layout from "src/components/layout/Layout";
import { useGetGenericIntegrationQuery } from 'src/services/integrations/integrations';
import { selectActiveAccountId } from "src/infrastructure/state/slices/activeAccountSlice";
import { DbtCoreIntegrationConfiguration, GenericIntegration, GenericIntegrationConfiguration, GenericIntegrationType, LookerIntegrationConfiguration, SnowflakeIntegrationConfiguration, TableauIntegrationConfiguration } from "src/services/integrations/types";
import PageLoader from "src/components/loaders/PageLoader";
import { InformationCircleIcon } from "@heroicons/react/24/solid";
import AdvancedBlock from "src/features/sources/connectedSources/AdvancedBlock";
import ConfigurationDetailsBlock from "src/features/sources/connectedSources/ConfigurationDetailsBlock";
import ScheduleBlock from "src/features/sources/connectedSources/ScheduleBlock";
import Input from "src/components/form/Input";
import { FormField } from "src/components/form/FormField";
import BottomBar from "src/features/sources/connectedSources/BottomBar";
import { Link } from "src/components/Link";
import { BookOpenIcon } from "@heroicons/react/24/outline";
import TopBar from "src/components/layout/TopBar";
import { eunoLinks } from "src/features/eunoLinks";

const ConnectSource = () => {
  const accountId = useSelector(selectActiveAccountId);
  const integrationIdInEdit = useParams().id || '';
  const integrationTypeinCreation = (useParams().type || '') as GenericIntegrationType | null;
  const getIntegration = useGetGenericIntegrationQuery({ accountId, integrationId: integrationIdInEdit }, { skip: !integrationIdInEdit });
  const [integration, setIntegration] = useState<GenericIntegration | undefined>(!integrationIdInEdit && integrationTypeinCreation ? createEmptyIntegration(integrationTypeinCreation) : undefined);
  const integrationType = integration?.integration_type || integrationTypeinCreation || null;

  useEffect(() => {
    if (!integration && getIntegration.data) {
      setIntegration(getIntegration.data);
    }
  }, [getIntegration.data, integration]);

  const documentation = integrationType ? documentationLinkPerIntegrationType[integrationType] : null;

  if (!integration) {
    return <PageLoader />;
  }

  return (
    <Layout>
      <TopBar />
      <div className="relative mt-12 flex-1 flex flex-col">
        {documentation && (
          <div className="p-4 rounded-lg border bg-white h-fit absolute right-8 top-0">
            <div className="text-lg text-text-primary mb-4 flex gap-2 items-center"><BookOpenIcon width="18" height="18" /> Need help?</div>
            <div>Visit our <Link text={`${documentation.label} integration documentation`} onClick={() => window.open(documentation.link, "_blank")} /></div>
          </div>
        )}
        <div className="flex flex-row-reverse gap-8 mx-auto w-fit flex-1">
          <div className="w-[600px]">
            <div className="flex flex-col">
              <div className="text-2xl">Source settings</div>
              <div className="text-tertiary mt-1 mb-auto">Add and manage connected sources and set up their schedule.</div>
            </div>
            <div className="mt-10">
              <div className="flex flex-col gap-8 relative">
                <div>
                  <span className="text-lg">General</span>
                  <div className="mt-4">
                    <FormField label="Name" labelClassName="w-44">
                      <Input
                        placeholder={`e.g my ${integration.integration_type} integration`}
                        value={integration.name}
                        onInputChange={(e: ChangeEvent<HTMLInputElement>) => setIntegration({ ...integration, name: e.target.value })}
                      />
                    </FormField>
                  </div>
                </div>
                <ConfigurationDetailsBlock
                  integration={integration}
                  setIntegration={setIntegration}
                />
                {integration.integration_type !== "dbt_core" && (
                  <ScheduleBlock
                    integration={integration}
                    setIntegration={setIntegration}
                  />
                )}
                {integration.integration_type === "dbt_core" && (
                  <div className="flex flex-row rounded-lg py-4 px-6 w-[40rem] bg-slate-100">
                    <InformationCircleIcon width="20" height="20" className="mr-2 text-slate-400" />
                    <span className='text-text-primary'>Euno will create an integration key upon source creation</span>
                  </div>
                )}
              </div>
            </div>
            <div className='my-10'>
              <div className="flex flex-col gap-6 relative">
                <AdvancedBlock integration={integration} setIntegration={setIntegration} />
              </div>
            </div>
          </div>
        </div>
        <BottomBar integration={integration} />
      </div>
    </Layout>
  );
};

const createEmptyIntegration = (type: GenericIntegrationType): GenericIntegration => {
  let configuration: GenericIntegrationConfiguration;
  switch (type) {
    case 'dbt_core':
      configuration = {} as DbtCoreIntegrationConfiguration;
      break;
    case 'looker':
      configuration = { client_id: '', client_secret: '', host: '' } as LookerIntegrationConfiguration;
      break;
    case 'tableau':
      configuration = { connect_uri: '', site: '', token_name: '', token_value: '', ssl_verify: true } as TableauIntegrationConfiguration;
      break;
    case 'snowflake':
      configuration = { host: '', user: '', password: '', role: '', warehouse: '', ssl_verify: true, table_to_use_for_query_history: '', use_snowflake_database: true } as SnowflakeIntegrationConfiguration;
      break;
  }
  return {
    id: 0,
    name: '',
    active: true,
    configuration,
    integration_type: type,
    health: '',
    created_by: '',
    created_at: new Date(),
    last_updated_by: '',
    last_updated_at: new Date(),
    last_run_status: 'draft'
  };
};

const documentationLinkPerIntegrationType: { [key in GenericIntegrationType]: { label: string; link: string } } = {
  dbt_core: { label: 'dbt Core', link: eunoLinks.DBT_CORE_DOCUMENTATION },
  looker: { label: 'Looker', link: eunoLinks.LOOKER_DOCUMENTATION },
  tableau: { label: 'Tableau', link: eunoLinks.TABLEAU_DOCUMENTATION },
  snowflake: { label: 'Snowflake', link: eunoLinks.SNOWFLAKE_DOCUMENTATION }
};

export default ConnectSource;