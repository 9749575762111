import { Bars3Icon, CircleStackIcon, TableCellsIcon } from "@heroicons/react/24/outline";
import { DbtCloudIcon, DbtDimensionIcon, EntityIcon, LookerIcon, MeasureIcon, MetricIcon, TableauIcon, SnowflakeIcon } from "../../../assets/images/icons/DelphiIcons";
import { DatabaseTechnology, NodeType, SubnodeType } from "./INode";

type NodeIconProps = {
    type: NodeType;
    iconSize?: number;
    withPadding?: boolean;
    withBackground?: boolean;
    subnodeType?: SubnodeType;
    greyVersion?: boolean;
    databaseTechnology?: DatabaseTechnology | null;
}

const greyVersionColor = "#64748B";

export const NodeIcon = ({ type, subnodeType, iconSize = 14, withPadding = false, withBackground = false, greyVersion = false, databaseTechnology }: NodeIconProps) => {

    const TypeToIconMap = {
        [NodeType.DataModel]: <DbtCloudIcon width={iconSize} height={iconSize} color={greyVersion ? greyVersionColor : "#FF694A"} />,
        [NodeType.Metric]: <DbtCloudIcon width={iconSize} height={iconSize} color={greyVersion ? greyVersionColor : "#FF694A"} />,
        [NodeType.DataSource]: <CircleStackIcon width={iconSize} height={iconSize} />,
        [NodeType.LookerExplore]: <LookerIcon width={iconSize} height={iconSize} />,
        [NodeType.LookerLook]: <LookerIcon width={iconSize} height={iconSize} />,
        [NodeType.LookerView]: <LookerIcon width={iconSize} height={iconSize} />,
        [NodeType.LookerDerivedView]: <LookerIcon width={iconSize} height={iconSize} />,
        [NodeType.GenericDataTransformation]: <TableCellsIcon width={iconSize} height={iconSize} />,
        [NodeType.Table]: <TableCellsIcon width={iconSize} height={iconSize} />,
        [NodeType.LookerDashboard]: <LookerIcon width={iconSize} height={iconSize} />,
        [NodeType.LookerTile]: <LookerIcon width={iconSize} height={iconSize} />,
        [NodeType.TableauWorkbook]: <TableauIcon width={iconSize} height={iconSize} />,
        [NodeType.TableauView]: <TableauIcon width={iconSize} height={iconSize} />,
        [NodeType.TableauCustomQuery]: <TableauIcon width={iconSize} height={iconSize} />,
        [NodeType.TableauDashboard]: <TableauIcon width={iconSize} height={iconSize} />,
        [NodeType.TableauEmbeddedDataSource]: <TableauIcon width={iconSize} height={iconSize} />,
        [NodeType.TableauPublishedDataSource]: <TableauIcon width={iconSize} height={iconSize} />,
        [NodeType.TableauStory]: <TableauIcon width={iconSize} height={iconSize} />,
        [NodeType.Column]: <Bars3Icon color={greyVersionColor} width={iconSize} height={iconSize} />,
        [NodeType.DbtColumn]: <Bars3Icon color={greyVersionColor} width={iconSize} height={iconSize} />,
        [NodeType.TableauDimension]: <Bars3Icon fill={greyVersionColor} width={iconSize} height={iconSize} />,
        [NodeType.TableauMeasure]: <MeasureIcon fill={greyVersionColor} width={iconSize} height={iconSize} />,
        [NodeType.TableCalculation]: <Bars3Icon color={greyVersionColor} width={iconSize} height={iconSize} />,
        [NodeType.LookerDimension]: <Bars3Icon color={greyVersionColor} width={iconSize} height={iconSize} />,
        [NodeType.LookerMeasure]: <MeasureIcon fill={greyVersionColor} width={iconSize} height={iconSize} />,
        [NodeType.DbtDimension]: <DbtDimensionIcon color="#FF694A" width={iconSize} height={iconSize} />,
        [NodeType.DbtMeasure]: <MeasureIcon fill={greyVersionColor} width={iconSize} height={iconSize} />,
        [NodeType.DbtSeed]: <DbtCloudIcon width={iconSize} height={iconSize} color={greyVersion ? greyVersionColor : "#FF694A"} />,
    };


    const DatabaseTechnologyToIconMap = {
        [DatabaseTechnology.snowflake]: <SnowflakeIcon width={iconSize} height={iconSize} />,
    };

    let icon: JSX.Element = subnodeType ? getSubnodeIcon(type, subnodeType, iconSize) : TypeToIconMap[type];

    if (!subnodeType && databaseTechnology && type === NodeType.Table) {
        icon = DatabaseTechnologyToIconMap[databaseTechnology] || icon;
    }

    return (
        <div className={`w-fit ${withBackground && 'bg-surface-light'} ${withPadding ? 'rounded p-2' : 'rounded'}`}>
            {icon}
        </div>
    );
};

const getSubnodeIcon = (type: NodeType, subnodeType: SubnodeType, iconSize: number) => {
    const SubnodeTypeToMap = {
        [SubnodeType.LookerConnectedView]: <LookerIcon width={iconSize} height={iconSize} />,
        [SubnodeType.LookerTile]: <LookerIcon width={iconSize} height={iconSize} />,
        [SubnodeType.LookerLook]: <LookerIcon width={iconSize} height={iconSize} />,
        [SubnodeType.Column]: <Bars3Icon color={greyVersionColor} width={iconSize} height={iconSize} />,
        [SubnodeType.Dimension]: <Bars3Icon fill={greyVersionColor} width={iconSize} height={iconSize} />,
        [SubnodeType.Measure]: <MeasureIcon fill={greyVersionColor} width={iconSize} height={iconSize} />,
        [SubnodeType.CustomField]: <Bars3Icon color={greyVersionColor} width={iconSize} height={iconSize} />,
        [SubnodeType.Schema]: <Bars3Icon color={greyVersionColor} width={iconSize} height={iconSize} />,
        [SubnodeType.Metric]: <MetricIcon fill={greyVersionColor} width={iconSize} height={iconSize} />,
        [SubnodeType.Entity]: <EntityIcon fill={greyVersionColor} width={iconSize} height={iconSize} />,
        [SubnodeType.TableCalculation]: <Bars3Icon color={greyVersionColor} width={iconSize} height={iconSize} />,
        [SubnodeType.TableauConnectedView]: <TableauIcon width={iconSize} height={iconSize} />,
        [SubnodeType.TableauDimension]: <Bars3Icon fill={greyVersionColor} width={iconSize} height={iconSize} />,
        [SubnodeType.TableauMeasure]: <MeasureIcon fill={greyVersionColor} width={iconSize} height={iconSize} />,
    };
    const TypeSpecificMap = new Map([
        [NodeType.DataModel, new Map([
            [SubnodeType.Dimension, <DbtDimensionIcon key='ModelDimension' fill="#FF694A" width={iconSize} height={iconSize} />],
        ])],
        [NodeType.DataSource, new Map([
            [SubnodeType.Dimension, <DbtDimensionIcon key='DataSourceDimension' fill="#FF694A" width={iconSize} height={iconSize} />],
        ])],
    ]);
    const typeSpecificIcon = TypeSpecificMap.get(type)?.get(subnodeType);
    if (typeSpecificIcon) {
        return typeSpecificIcon;
    }
    return SubnodeTypeToMap[subnodeType];
};
