type LinkProps = {
    text: string;
    onClick: () => void;
};

export const Link = ({ text, onClick }: LinkProps) => {
    return (
        <a onClick={onClick}
            target="_blank"
            rel="noreferrer"
            className="text-primary hover:underline cursor-pointer">
            {text}
        </a>
    );
};