import { ArrowPathIcon } from "@heroicons/react/24/outline";
import { XMarkIcon, ArrowDownTrayIcon, ArrowTopRightOnSquareIcon } from "@heroicons/react/24/solid";
import { useState, useEffect } from "react";
import Markdown from "react-markdown";
import { useSelector } from "react-redux";
import Button from "src/components/button/Button";
import { ButtonTypes } from "src/components/button/types";
import Modal from "src/components/Modal/Modal";
import { Tabs } from "src/components/Tabs";
import { notify } from "src/components/Toaster";
import { SyncRun } from "src/features/dataModelSync/types";
import { selectActiveAccountId } from "src/infrastructure/state/slices/activeAccountSlice";
import { useGetRunReportQuery, useCancelRunMutation, useLazyGetArtifactsQuery, useRunJobMutation } from "src/services/actions/actions";
import { extractErrorMessage } from "src/services/api";

export const SyncRunsActionsColumn = ({ run }: { run: SyncRun }) => {
    const [showRunReport, setShowRunReport] = useState(false);
    const accountId = useSelector(selectActiveAccountId);
    const getRunReport = useGetRunReportQuery({ accountId, runId: run.id }, { skip: !showRunReport });
    const [cancelRunMutation] = useCancelRunMutation();
    const [getArtifactsQuery] = useLazyGetArtifactsQuery();
    const [runJob] = useRunJobMutation();

    useEffect(() => {
        if (getRunReport.error) {
            console.error(getRunReport.error);
            notify(`Failed to fetch run report: ${extractErrorMessage(getRunReport.error).message}`, 'error');
        }
    }, [getRunReport.error]);

    const stopRun = async () => {
        try {
            await cancelRunMutation({ accountId, runId: run.id }).unwrap();
            notify('Run stopped successfully', 'success');
        }
        catch (e) {
            notify(`Failed to stop run: ${extractErrorMessage(e).message}`, 'error');
        }
    };

    const reRun = async () => {
        try {
            await runJob({ accountId, jobId: run.jobId }).unwrap();
            notify('Run re-triggered successfully', 'success');
        }
        catch (e) {
            notify(`Failed to re-trigger run: ${extractErrorMessage(e).message}`, 'error');
        }
    };

    const download = async () => {
        try {
            for (const artifact of run.artifacts) {
                await getArtifactsQuery({ accountId, runId: run.id, artifact: artifact.name }).unwrap();
            }
        }
        catch (e) {
            notify(`Failed to download artifacts: ${extractErrorMessage(e).message}`, 'error');
        }
    };

    return (
        <div className="flex items-center w-60">
            <div className="w-24 flex gap-2 items-center">
                {
                    run.state === 'running' && (
                        <ActionButtonWrapper onClick={stopRun}>
                            <XMarkIcon width="12" height="12" />
                        </ActionButtonWrapper>
                    )
                }
                {
                    run.state === 'failed' && (
                        <ActionButtonWrapper onClick={reRun}>
                            <ArrowPathIcon width="12" height="12" />
                        </ActionButtonWrapper>
                    )
                }
                {
                    run.artifacts.length > 0 && (
                        <ActionButtonWrapper onClick={download}>
                            <ArrowDownTrayIcon width="12" height="12" />
                        </ActionButtonWrapper>
                    )
                }
            </div>
            {
                run.hasRunReport && (
                    <Button
                        type={ButtonTypes.primary}
                        onClick={() => setShowRunReport(true)}
                        text="Open Report"
                        icon={<ArrowTopRightOnSquareIcon width="14" height="14" />}
                    />
                )
            }
            <RunReportModal isOpen={showRunReport && getRunReport.isSuccess} onClose={() => setShowRunReport(false)} run={run} report={getRunReport.data || ''} />
        </div>
    );
};


const RunReportModal = ({ isOpen, onClose, run, report }: { isOpen: boolean, onClose: () => void, run: SyncRun, report: string }) => {
    const [activeTab, setActiveTab] = useState<'Run report' | 'Run configuration'>('Run report');
    return (
        <Modal isOpen={isOpen} onClose={onClose} maxWidth="max-w-4xl" title={`Run Report - ${run.title}`} buttons={[{ text: 'Done', type: ButtonTypes.primary, onClick: onClose }]}>
            <div className="overflow-y-auto h-[70vh]">
                <div className="w-fit mx-auto my-2">
                    <Tabs
                        items={[
                            { text: 'Run report', onClick: () => setActiveTab('Run report'), isActive: activeTab === 'Run report' },
                            { text: 'Run configuration', onClick: () => setActiveTab('Run configuration'), isActive: activeTab === 'Run configuration' }
                        ]}
                    />
                </div>
                {
                    activeTab === 'Run report' && (
                        <Markdown>
                            {report}
                        </Markdown>
                    )
                }
                {
                    activeTab === 'Run configuration' && (
                        <div>
                            <pre className="bg-slate-50 p-4 rounded-lg text-sm">{JSON.stringify(run.configuration, null, 2)}</pre>
                        </div>
                    )
                }
            </div>
        </Modal>
    );
};

const ActionButtonWrapper = ({ children, onClick }: { children: JSX.Element; onClick: () => void }) => (
    <div className="rounded-lg p-1 border border-border text-gray-400 hover:bg-slate-100 w-fit h-fit cursor-pointer" onClick={onClick}>
        {children}
    </div>
);
