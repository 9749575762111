import { SyncRunStatus } from "src/features/dataModelSync/types";

export const DataSyncRunStatusLabel = ({ status, className = '' }: { status: SyncRunStatus, className?: string }) => {
    let textColor = '';
    let bgColor = '';
    switch (status) {
        case 'completed':
            textColor = 'text-green-800';
            bgColor = 'bg-green-100';
            break;
        case 'cancelled':
        case 'failed':
            textColor = 'text-red-700';
            bgColor = 'bg-red-100';
            break;
        case 'running':
            textColor = 'text-lilac-800';
            bgColor = 'bg-lilac-100';
            break;
    }
    return (
        <div className={`capitalize text-sm font-medium rounded-full px-2 py-1 ${textColor} ${bgColor} ${className}`}>
            {status}
        </div>
    );
};