import Table from '../../components/Table/Table';
import { Operation } from '../operations/Operation';
import { IProject } from '../projects/IProject';
import { useCallback, useMemo, useState } from 'react';
import { useGetProjectsQuery } from '../../services/projects/projects';
import { EyeIcon } from '@heroicons/react/24/solid';
import { selectActiveAccountId } from '../../infrastructure/state/slices/activeAccountSlice';
import { useSelector } from 'react-redux';
import { useGetAccountOperationsQuery } from '../../services/operations';
import OperationStatusLabel from '../operations/OperationStatusLabel';
import { LogsModal } from './AccountLogsModal';
import { dateFormats, utcToLocal } from '../../infrastructure/dateUtilities';

type OperationNameToDisplayNameType = {
  [key: string]: string;
}

const OperationNameToDisplayName: OperationNameToDisplayNameType = {
  'integration_run':  'Integration Polling',
  'sync_to_lookml': 'Sync to LookML',
  'process_uploaded_build': 'Process Uploaded Build'
};

const getTableColumn = ({ projects, viewLogs }: { projects: IProject[]; viewLogs: (operation: Operation) => void }) => {
  const tableColumns = [
    {
      name: 'TIMESTAMP',
      selector: (row: unknown) => (
        <span className="text-text-primary">{utcToLocal((row as Operation).start_time, dateFormats.monthsDaysHoursAndMinutes)}</span>
      )
    },
    {
      name: 'PROJECT',
      selector: (row: unknown) => {
        const project = projects.find(({ id }) => id === (row as Operation).project_id);
        return project?.name || '';
      }
    },
    {
      name: 'TYPE',
      selector: (row: unknown) => {
	return OperationNameToDisplayName[(row as Operation).name] || (row as Operation).name;
      }
    },
    {
      name: 'STATUS',
      selector: (row: unknown) => {
        return (
          <div className="flex items-center">
            <OperationStatusLabel status={(row as Operation).status} />
            <EyeIcon
              width="20"
              height="20"
              className="ml-4 cursor-pointer text-slate-400"
              onClick={() => viewLogs(row as Operation)}
            />
          </div>
        );
      }
    }
  ];
  return tableColumns;
};

const AccountOperations = () => {
  const [page, setPage] = useState(1);
  const accountId = useSelector(selectActiveAccountId);
  const projectsQueryResponse = useGetProjectsQuery();
  const [pageSize, setPageSize] = useState(10);
  const getAccountOperations = useGetAccountOperationsQuery({ accountId, page, pageSize }, { skip: !accountId });
  const { total = 0, items: operations = [] } = getAccountOperations.data || {};
  const [showLogsModal, setShowLogsModal] = useState(false);
  const [selectedOperation, setSelectedOperation] = useState<Operation | null>(null);
  const viewLogs = useCallback((operation: Operation) => {
    setSelectedOperation(operation);
    setShowLogsModal(true);
  }, []);
  const tableColumns = useMemo(
    () => getTableColumn({ projects: projectsQueryResponse?.data || [], viewLogs }),
    [projectsQueryResponse, viewLogs]
  );
  return (
    <div className="mb-8">
      <div className="mb-2 text-lg text-text-primary">Operations</div>
      <Table data={operations} columns={tableColumns} pagination={{ page, pageSize, total, setPage, setPageSize }} maxBodyHeight='65vh' />
      <LogsModal isOpen={showLogsModal} operation={selectedOperation} onClose={() => setShowLogsModal(false)} />
    </div>
  );
};

export default AccountOperations;
