import { ArrowDownRightIcon, ArrowUpLeftIcon, ChevronDownIcon, GlobeAltIcon, StarIcon, UserCircleIcon } from "@heroicons/react/24/outline";
import { ReactNode, useState } from "react";
import { useSelector } from "react-redux";
import { Tooltip } from "react-tooltip";
import DropdownMenu from "src/components/DropdownMenu/DropdownMenu";
import { DBT_TYPES, IExpandedNode, NodeType } from "src/features/models/discover/INode";
import { ResourceSidepaneContentsOverview } from "src/features/models/discover/resourceSidepane/overviewTab/ResourceSidepaneContentsOverview";
import { ResourceSidepaneOverviewConnections } from "src/features/models/discover/resourceSidepane/overviewTab/ResourceSidepaneOverviewConnections";
import { ResourceSidepaneUsageOverview } from "src/features/models/discover/resourceSidepane/overviewTab/ResourceSidepaneUsageOverview";
import { ResourceTab } from "src/features/models/discover/resourceSidepane/types";
import { dateFormats, utcToLocal } from "src/infrastructure/dateUtilities";
import { selectActiveAccountId } from "src/infrastructure/state/slices/activeAccountSlice";
import { useGetCustomPropertiesQuery } from "src/services/nodes/nodes";

type ResourceSidepaneOverviewProps = {
    resource: IExpandedNode;
    setResourceId: (id: string) => void;
    setActiveTab: (tab: ResourceTab) => void;
}

export const ResourceSidepaneOverview = ({ resource, setResourceId, setActiveTab }: ResourceSidepaneOverviewProps) => {
    const [propertiesSectionExpanded, setPropertiesSectionExpanded] = useState(false);
    return (
        <div className="flex flex-col bg-surface-light h-full overflow-y-auto max-h-[80vh]">
            <div className="flex justify-between px-4 gap-4">
                <Description type={resource.type} text={resource.description} />
                <GeneralInfo resource={resource} />
            </div>
            <div className="flex justify-between px-4 gap-4">
                <PropertiesSection propertiesSectionExpanded={propertiesSectionExpanded} setPropertiesSectionExpanded={setPropertiesSectionExpanded} resource={resource} />
                {
                    !propertiesSectionExpanded && (
                        <>
                            <ResourceSidepaneUsageOverview setActiveTab={setActiveTab} setResourceId={setResourceId} resource={resource} />
                        </>
                    )
                }
            </div>
            {
                !propertiesSectionExpanded && (
                    <>
                        <ResourceSidepaneOverviewConnections resource={resource} />
                        <ResourceSidepaneContentsOverview resource={resource} />
                    </>
                )
            }
        </div>
    );
};

const Description = ({ text, type }: { text: string, type: NodeType }) => {
    const [showMore, setShowMore] = useState(false);
    const maxWords = 40;
    if (!text && DBT_TYPES.includes(type)) {
        return (
            <div className="text-center content-center w-[61%] mt-4 min-h-[60px] border text-base text-tertiary border-border rounded">
                No description
            </div>
        );
    }
    else if (!text) {
        return null;
    }
    const numberOfWords = text.split(' ').length;
    return (
        <div className="w-[61%] mt-4 text-base">
            <span className="text-text-primary break-all">{text.split(' ').slice(0, showMore ? Infinity : maxWords).join(' ')}</span>
            {
                numberOfWords > maxWords && (
                    <span className="text-tertiary cursor-pointer ml-1" onClick={() => setShowMore(!showMore)}>{showMore ? 'Show less' : 'Show more'}</span>
                )
            }
        </div>
    );
};

const GeneralInfo = ({ resource }: { resource: IExpandedNode }) => {
    if (!resource.favouriteCount && !resource.owner && !resource.nativeLastDataUpdate && !resource.lastUpdatedAt && !resource.createdAt && !resource.externalLinks?.length) {
        return null;
    }
    return (
        <div className="w-[28%] mt-4 flex flex-col gap-1.5 text-slate-400 whitespace-nowrap flex-1 text-sm">
            {
                resource.favouriteCount && (
                    <div className="flex gap-1 items-center"><StarIcon width="14" height="14" />Favourited {resource.favouriteCount} times</div>
                )
            }
            {
                resource.owner && (
                    <div className="flex gap-1 items-center"><UserCircleIcon width="14" height="14" />{resource.owner}</div>
                )
            }
            {
                resource.nativeLastDataUpdate && (
                    <div>Last extracted on {utcToLocal(resource.nativeLastDataUpdate, dateFormats.date)}</div>
                )
            }
            {
                resource.lastUpdatedAt && (
                    <div>
                        Last updated on {utcToLocal(resource.lastUpdatedAt, dateFormats.date)}
                        {
                            resource.lastUpdatedBy && (
                                <div> by <span className="text-slate-500">{resource.lastUpdatedBy}</span></div>
                            )
                        }
                    </div>
                )
            }
            {
                resource.createdAt && (
                    <div>
                        Created on {utcToLocal(resource.createdAt, dateFormats.date)}
                        {
                            resource.createdBy && (
                                <div> by <span className="text-slate-500">{resource.createdBy}</span></div>
                            )
                        }
                    </div>
                )
            }
            {
                resource.externalLinks?.length > 0 && (
                    <DropdownMenu items={resource.externalLinks.map(link => ({ name: link.label, onClick: () => window.open(link.url, '_blank') }))}>
                        <div className="flex items-center gap-1"><GlobeAltIcon width="14" height="14" /> Links <ChevronDownIcon width="12" height="12" /></div>
                    </DropdownMenu>
                )
            }
        </div>
    );
};

type PropertiesSectionProps = {
    propertiesSectionExpanded: boolean;
    setPropertiesSectionExpanded: (expanded: boolean) => void;
    resource: IExpandedNode;
}
const PropertiesSection = ({ resource, propertiesSectionExpanded, setPropertiesSectionExpanded }: PropertiesSectionProps) => {
    const tags = resource.tags || [];
    const meta = Object.entries(resource.meta || {});
    const accountId = useSelector(selectActiveAccountId);
    const getCustomProperties = useGetCustomPropertiesQuery({ accountId });
    const populatedCustomProperties = getCustomProperties.data?.filter(p => resource.customProperties[p.name]) || [];
    const propertiesCanBeExpanded = populatedCustomProperties.length > 2 || tags.length > 3 || meta.length > 2;
    if (populatedCustomProperties.length === 0 && tags.length === 0 && meta.length === 0) {
        return null;
    }
    return (
        <div className={`${propertiesSectionExpanded ? 'flex-1' : 'w-[45%]'} mt-4 p-2 rounded border border-border bg-surface-light flex flex-col gap-2 overflow-x-auto`}>
            {
                populatedCustomProperties.length > 0 && (
                    <div>
                        <div className="text-secondary mb-1">Organizational properties</div>
                        {
                            populatedCustomProperties.slice(0, propertiesSectionExpanded ? Infinity : 2).map(p => (
                                <div key={p.name} className="flex gap-1 items-center">
                                    <Tooltip anchorSelect={`#${p.name}`}>{p.description}</Tooltip>
                                    <div id={p.name} className="text-slate-400 cursor-help">{p.name}</div>
                                    <div className="rounded bg-mint-100 text-mint-800 px-0.5">{resource.customProperties[p.name] as ReactNode}</div>
                                </div>
                            ))
                        }
                        {
                            !propertiesSectionExpanded && populatedCustomProperties.length > 2 && (
                                <div className="rounded bg-mint-100 text-mint-800 px-0.5 w-fit">+{populatedCustomProperties.length - 2} more</div>
                            )
                        }
                    </div>
                )
            }
            {
                tags.length > 0 && (
                    <div>
                        <div className="text-secondary mb-1">Tags</div>
                        <div className="flex gap-1 flex-wrap">
                            {
                                tags.slice(0, propertiesSectionExpanded ? Infinity : 3).map(tag => (
                                    <div key={tag} className="rounded bg-lilac-100 text-lilac-800 px-0.5 w-fit">{tag}</div>
                                ))
                            }
                            {
                                !propertiesSectionExpanded && tags.length > 3 && (
                                    <div className="rounded bg-lilac-100 text-lilac-800 px-0.5 w-fit">+{tags.length - 3}</div>
                                )
                            }
                        </div>
                    </div>
                )
            }
            {
                meta.length > 0 && (
                    <div>
                        <div className="text-secondary mb-1">Meta</div>
                        <div className="flex flex-col gap-1 flex-wrap">
                            {
                                meta.slice(0, propertiesSectionExpanded ? Infinity : 2).map(([key, value]) => (
                                    <div key={key} className="flex gap-1">
                                        <div className="text-slate-400 cursor-help">{key}</div>
                                        <div className="rounded bg-mint-100 text-mint-800 px-0.5 w-fit">{value}</div>
                                    </div>
                                ))
                            }
                            {
                                !propertiesSectionExpanded && meta.length > 2 && (
                                    <div className="rounded bg-mint-100 text-mint-800 px-0.5 w-fit">+{meta.length - 2} more</div>
                                )
                            }
                        </div>
                    </div>
                )
            }
            {
                propertiesCanBeExpanded && (
                    <div className="text-slate-300 hover:text-slate-400 cursor-pointer w-fit ml-auto -mt-2" onClick={() => setPropertiesSectionExpanded(!propertiesSectionExpanded)}>
                        {
                            propertiesSectionExpanded ? <ArrowUpLeftIcon width="14" height="14" /> : <ArrowDownRightIcon width="14" height="14" />
                        }
                    </div>
                )
            }
        </div>
    );
};