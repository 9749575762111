import { Dialog, Transition } from '@headlessui/react';
import { Fragment } from 'react';
import { XMarkIcon } from '@heroicons/react/24/solid';
import { Button } from '../button/Button';
import { ModalButton } from './Types';

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  title: string | JSX.Element;
  icon?: JSX.Element;
  buttons?: ModalButton[];
  children: React.ReactNode;
  maxWidth?: 'max-w-md' | 'max-w-lg' | 'max-w-xl' | 'max-w-2xl' | 'max-w-3xl' | 'max-w-4xl';
  withHeader?: boolean;
  containerClassName?: string;
}

export default function Modal({ isOpen, onClose, title, icon, children, buttons = [], maxWidth = 'max-w-md', withHeader = true, containerClassName = '' }: ModalProps) {
  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-40" onClose={onClose}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-200"
            leave="ease-in duration-200"
            leaveFrom="opacity-200"
            leaveTo="opacity-0">
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95">
                <Dialog.Panel
                  className={`${maxWidth} w-full transform overflow-hidden rounded-2xl bg-white text-left align-middle text-base shadow-xl transition-all`}>
                  {
                    withHeader && (
                      <div className="flex h-10 items-center justify-center bg-primary text-white">
                        {icon}
                        {title}
                        <div className="absolute cursor-pointer" style={{ right: '15px' }} onClick={onClose} data-test-id="close-modal-button">
                          <XMarkIcon width="24" height="24" />
                        </div>
                      </div>
                    )
                  }
                  <div className={`p-4 overflow-y-auto max-h-[85vh] ${containerClassName}`}>{children}</div>

                  {<ModalButtons buttons={buttons} />}
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}

function ModalButtons({ buttons }: { buttons: ModalButton[] }) {
  if (buttons.length === 0) {
    return null;
  }
  return (
    <div className="flex mb-4 justify-between mx-4">
      <div className="flex gap-4">
        {buttons.filter(button => button.position === 'left').map(button => {
          // eslint-disable-next-line
          const { position, ...nativeButtonProps } = button;
          return <Button {...nativeButtonProps} key={button.text} />;
        })}
      </div>
      <div className="flex gap-4">
        {buttons.filter(button => button.position !== 'left').map(button => {
        // eslint-disable-next-line
          const { position, ...nativeButtonProps } = button;
          return <Button {...nativeButtonProps} key={button.text} />;
        })}
      </div>
    </div>
  );
}
