export enum OperationStatus {
  Pending = 'pending',
  Running = 'running',
  Publishing = 'publishing',
  Completed = 'completed',
  Failed = 'failed',
  Cancelled = 'cancelled',
  Canceling = 'canceling'
}

export type OperationSummary = {
  id: number;
  name: OperationType;
  status: OperationStatus;
  start_time: string;
  end_time: string;
  project_id: number;
  trigger: string;
  observer_id: string;
  observer_type: string;
  result: unknown;
  change_id: number | null;  
}

export type Operation = OperationSummary & {
  result: unknown;
}

export type OperationType = 'create_pull_request' | 'generate_git_diff'