import { GenericIntegration, LookerIntegrationConfiguration, SnowflakeIntegrationConfiguration, TableauIntegrationConfiguration } from "src/services/integrations/types";

export const transformBackendIntegrationToLocal = (integration: GenericIntegration): GenericIntegration => {
    const configuration = { ...integration.configuration };
    switch (integration.integration_type) {
        case 'looker':
            (configuration as LookerIntegrationConfiguration).client_secret = '';
            break;
        case 'snowflake':
            (configuration as SnowflakeIntegrationConfiguration).password = '';
            break;
        case 'tableau':
            (configuration as TableauIntegrationConfiguration).token_value = '';
            break;
        case 'dbt_core':
            break;
        default:
            throw new Error(`Unknown integration type: ${integration.integration_type}`);
    }
    return {
        ...integration,
        configuration
    };
};

export const transformLocalIntegrationToBackend = (integration: GenericIntegration): GenericIntegration => {
    const configuration = { ...integration.configuration };
    switch (integration.integration_type) {
        case 'looker':
            (configuration as LookerIntegrationConfiguration).client_secret = (configuration as LookerIntegrationConfiguration).client_secret || undefined;
            break;
        case 'snowflake':
            (configuration as SnowflakeIntegrationConfiguration).password = (configuration as SnowflakeIntegrationConfiguration).password || undefined;
            break;
        case 'tableau':
            (configuration as TableauIntegrationConfiguration).token_value = (configuration as TableauIntegrationConfiguration).token_value || undefined;
            break;
        case 'dbt_core':
            break;
        default:
            throw new Error(`Unknown integration type: ${integration.integration_type}`);
    }
    return {
        ...integration,
        configuration
    };
};