import { useSelector } from 'react-redux';
import { selectActiveAccountId } from '../../infrastructure/state/slices/activeAccountSlice';
import { useGetAccountOperationQuery, useGetOperationLogsQuery } from '../../services/operations';

const OperationLogsContainer = ({ operationId }: { operationId: number }) => {
  const accountId = useSelector(selectActiveAccountId);
  const getOperation = useGetAccountOperationQuery(
    { accountId, operationId: operationId || 0 },
    { skip: !operationId }
  );
  const operation = getOperation.data;
  const getOperationLogs = useGetOperationLogsQuery(
    { accountId, operationId: operationId || 0 },
    { skip: !operation || !accountId }
  );
  const logs = getOperationLogs?.data || '';
  return (
    <div className="max-h-64 w-full overflow-auto whitespace-pre-line rounded-lg border border-slate-200 bg-surface-light p-2 text-sm text-text-primary">
      {getOperationLogs.isLoading || getOperationLogs.isFetching ? 'loading..' : logs}
    </div>
  );
};

export default OperationLogsContainer;
