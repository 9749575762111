import { AdjustmentsVerticalIcon, Bars3Icon } from "@heroicons/react/24/solid";
import Button from "../../button/Button";
import { ButtonTypes } from "../../button/types";
import { useState, useRef, useEffect } from "react";
import { DropResult, DragDropContext, Draggable, DroppableProps, Droppable } from "react-beautiful-dnd";
import { useClickAway } from "react-use";
import { TableColumnProps } from "../types";
import { events, trackEvent } from "src/infrastructure/analytics";
import Checkbox from "src/components/form/Checkbox";
import { ArrowToLineIcon } from "src/assets/images/icons/DelphiIcons";
import Input from "src/components/form/Input";
import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";

type SelectorColumnProps<T> = {
    columns: TableColumnProps<T>[];
    updateColumns: (columns: TableColumnProps<T>[]) => void;
    selectedColumns: TableColumnProps<T>[];
    tableName: string
}

export const SelectorColumn = <T,>({ columns, updateColumns, selectedColumns, tableName }: SelectorColumnProps<T>) => {
    const [showSelector, setShowSelector] = useState(false);
    const [selectedHiddenColumns, setSelectedHiddenColumns] = useState<string[]>([]);
    const [selectedShownColumns, setSelectedShownColumns] = useState<string[]>([]);
    const [searchQuery, setSearchQuery] = useState<string>('');
    const selectorRef = useRef<HTMLDivElement>(null);
    const hideSelector = () => setShowSelector(false);
    useClickAway(selectorRef, hideSelector);

    const showSelected = () => {
        updateColumns([...selectedColumns, ...columns.filter(c => selectedHiddenColumns.includes(c.name))]);
        setSelectedHiddenColumns([]);
    };

    const hideSelected = () => {
        updateColumns(selectedColumns.filter(c => !selectedShownColumns.includes(c.name)));
        setSelectedShownColumns([]);
    };

    return (
        <div>
            <Button className="mb-2" type={ButtonTypes.secondary} text="Customize columns" onClick={() => setShowSelector(true)} icon={<AdjustmentsVerticalIcon width="16" height="16" />} />
            {
                showSelector && (
                    <div ref={selectorRef} className="p-2 absolute min-w-[650px] top-18 z-20 rounded-md border border-slate-200 bg-white shadow">
                        <div className="flex items-center gap-1 text-text-primary">
                            <AdjustmentsVerticalIcon height={16} width={16} />
                            Customize columns
                        </div>
                        <div className="flex gap-4 mt-4">
                            <div className="flex-1">
                                <div className="text-tertiary mb-1 text-sm">Select columns to show</div>
                                <HiddenColumnsList
                                    columns={columns}
                                    selectedColumns={selectedColumns}
                                    selectedHiddenColumns={selectedHiddenColumns}
                                    setSelectedHiddenColumns={setSelectedHiddenColumns}
                                    searchQuery={searchQuery}
                                    setSearchQuery={setSearchQuery}
                                />
                            </div>
                            <div className="flex flex-col gap-2 mt-6">
                                <div className="items-center text-text-primary rounded-lg border border-border py-2 px-6 flex flex-col gap-1 bg-white hover:bg-slate-50 cursor-pointer" onClick={showSelected}>
                                    <ArrowToLineIcon width={16} height={16} />
                                    Show
                                </div>
                                <div className="items-center text-text-primary rounded-lg border border-border py-2 px-6 flex flex-col gap-1 bg-white hover:bg-slate-50 cursor-pointer" onClick={hideSelected}>
                                    <ArrowToLineIcon className="rotate-180" width={16} height={16} />
                                    Hide
                                </div>
                            </div>
                            <div className="flex-1">
                                <div className="text-tertiary mb-1 text-sm">Drag the the column handles ro reorder</div>
                                <DraggableColumList
                                    tableName={tableName}
                                    columns={selectedColumns}
                                    updateColumns={updateColumns}
                                    selectedShownColumns={selectedShownColumns}
                                    setSelectedShownColumns={setSelectedShownColumns}
                                />
                            </div>
                        </div>
                    </div>
                )
            }
        </div>
    );
};


type DraggableColumListProps<T> = {
    columns: TableColumnProps<T>[];
    updateColumns: (columns: TableColumnProps<T>[]) => void;
    tableName: string;
    selectedShownColumns: string[];
    setSelectedShownColumns: (columns: string[]) => void;
}

const DraggableColumList = <T,>({ columns, updateColumns, tableName, selectedShownColumns, setSelectedShownColumns }: DraggableColumListProps<T>) => {
    const onDragEnd = (result: DropResult) => {
        if (!result.destination) return;
        const newColumns = Array.from(columns);
        const [reorderedItem] = newColumns.splice(result.source.index, 1);
        trackEvent(events.dataModelColumnReordered, { column: reorderedItem.name, tableName });
        newColumns.splice(result.destination.index, 0, reorderedItem);
        updateColumns(newColumns);
    };

    return (
        <div className="border border-slate-200 rounded-md bg-surface-light overflow-y-auto h-[70vh] flex-1">
            <DragDropContext onDragEnd={onDragEnd}>
                <StrictModeDroppable droppableId="table-column-selector">
                    {(provided) => (
                        <div
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                            className="text-text-primary py-1"
                        >
                            {columns.map((column, index) => {
                                const key = `column-selector-${column.name.toLowerCase().replace(/ /g, '-')}`;
                                return (
                                    <Draggable key={key} draggableId={key} index={index} isDragDisabled={column.isNotRemovable}>
                                        {(provided) => (
                                            <>
                                                <div
                                                    className={`py-2 px-2 flex gap-2 text-sm items-center ${column.isNotRemovable && 'text-slate-400 cursor-not-allowed'}`}
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    {...provided.dragHandleProps}
                                                >
                                                    {
                                                        !column.isNotRemovable ? (
                                                            <>
                                                                <Bars3Icon height={12} width={12} className="text-slate-500" />
                                                                <Checkbox
                                                                    value={selectedShownColumns.includes(column.name)}
                                                                    setValue={newValue => {
                                                                        setSelectedShownColumns(newValue ? [...selectedShownColumns, column.name] : selectedShownColumns.filter(c => c !== column.name));
                                                                    }}
                                                                />
                                                            </>
                                                        ) : <div className="w-9"></div>
                                                    }
                                                    {column.name}
                                                </div>
                                                {
                                                    index !== columns.length - 1 && <div className="h-[1px] bg-slate-200"></div>
                                                }
                                            </>
                                        )}
                                    </Draggable>
                                );
                            }
                            )}
                            {provided.placeholder}
                        </div>
                    )}
                </StrictModeDroppable>
            </DragDropContext>
        </div>
    );
};

const StrictModeDroppable = ({ children, ...props }: DroppableProps) => {
    const [enabled, setEnabled] = useState(false);
    useEffect(() => {
        const animation = requestAnimationFrame(() => setEnabled(true));
        return () => {
            cancelAnimationFrame(animation);
            setEnabled(false);
        };
    }, []);
    if (!enabled) {
        return null;
    }
    return <Droppable {...props}>{children}</Droppable>;
};

const HiddenColumnsList = <T,>({ columns, selectedColumns, selectedHiddenColumns, setSelectedHiddenColumns, searchQuery, setSearchQuery }: { columns: TableColumnProps<T>[], selectedColumns: TableColumnProps<T>[], selectedHiddenColumns: string[], setSelectedHiddenColumns: (columns: string[]) => void, searchQuery: string, setSearchQuery: (query: string) => void }) => {
    const hiddenColumns = columns.filter(c => !selectedColumns.some(sc => sc.name === c.name) && c.name.toLowerCase().includes(searchQuery.toLowerCase()));
    const columnGroups = [...new Set(hiddenColumns.map(c => c.group))];
    return (
        <div className="border border-slate-200 rounded-md bg-surface-light overflow-y-auto h-[70vh] flex-1">
            <Input
                placeholder="Search"
                value={searchQuery}
                onInputChange={e => setSearchQuery(e.target.value)}
                rounded="rounded-md"
                className="p-1 !w-full"
                height="h-8"
                icon={<MagnifyingGlassIcon width="14" height="14" />}
            />
            {
                columnGroups.map(group => {
                    const columnsInGroup = hiddenColumns.filter(c => c.group === group).sort((a, b) => a.name.localeCompare(b.name));
                    return (
                        <div key={group}>
                            {
                                group && (
                                    <div key={group} className="mt-2 mb-1 px-2 flex gap-2 text-sm items-center text-slate-400">
                                        {group}
                                    </div>
                                )
                            }
                            {
                                columnsInGroup.map((column, index) => (
                                    <>
                                        <div key={column.name} className="py-2 px-2 flex gap-2 text-sm items-center">
                                            <Checkbox
                                                value={selectedHiddenColumns.includes(column.name)}
                                                setValue={newValue => {
                                                    setSelectedHiddenColumns(newValue ? [...selectedHiddenColumns, column.name] : selectedHiddenColumns.filter(c => c !== column.name));
                                                }} />
                                            {column.name}
                                        </div>
                                        {index !== columns.length - 1 && <div className="h-[1px] bg-slate-200"></div>}
                                    </>
                                ))
                            }
                        </div>
                    );
                })
            }
        </div>
    );
};