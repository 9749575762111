import BuildLifecycleState from './BuildLifecycleState';

const BuildStatusLabel = ({ status }: { status: BuildLifecycleState }) => {
    const statusLabelMap = {
        [BuildLifecycleState.Uploading]: {
            label: 'Uploading',
            textColor: 'text-slate-400',
        },
        [BuildLifecycleState.UploadIncomplete]: {
            label: 'Upload Incomplete',
            textColor: 'text-slate-400',
        },
        [BuildLifecycleState.PendingProcessing]: {
            label: 'Pending Processing',
            textColor: 'text-slate-400',
        },
        [BuildLifecycleState.Processing]: {
            label: 'Processing',
            textColor: 'text-slate-400',
        },
        [BuildLifecycleState.Processed]: {
            label: 'Publishing',
            textColor: 'text-green-600',
        },
        [BuildLifecycleState.Completed]: {
            label: 'Completed',
            textColor: 'text-green-600',
        },
        [BuildLifecycleState.Failed]: {
            label: 'Failed',
            textColor: 'text-red-600',
        },
    };

    return (
      <span
        className={`font-medium ${statusLabelMap[status].textColor}`}>
        {statusLabelMap[status].label}
      </span>
    );
  };

  export default BuildStatusLabel;