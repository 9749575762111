import { useEffect, useState } from 'react';
import Modal from '../../../../components/Modal/Modal';
import { ButtonTypes } from '../../../../components/button/types';
import { IProjectConfiguration } from '../../IProject';
import IBranch, { IBranchSyncConfiguration } from '../IBranch';
import { CodeBracketIcon, FolderIcon, MinusCircleIcon, PlusCircleIcon } from '@heroicons/react/24/solid';
import { BranchIcon, GithubIcon } from '../../../../assets/images/icons/DelphiIcons';
import Button from '../../../../components/button/Button';
import AddSyncConfiguration from './add/AddSyncConfiguration';
import { useGetProjectConfigurationQuery, useUpdateProjectConfigurationMutation } from '../../../../services/projects/projects';
import { useSelector } from 'react-redux';
import { selectActiveProject } from '../../../../infrastructure/state/slices/activeProjectSlice';
import { extractErrorMessage } from '../../../../services/api';
import { notify } from '../../../../components/Toaster';
import waitForOperationToComplete from '../../../../infrastructure/waitForOperation';
import { selectActiveAccountId } from '../../../../infrastructure/state/slices/activeAccountSlice';
import { OperationStatus } from '../../../operations/Operation';
import { ChevronDoubleUpIcon } from '@heroicons/react/24/outline';

interface BranchSyncConfigurationModalProps {
  isOpen: boolean;
  onClose: () => void;
  projectConfiguration: IProjectConfiguration;
  branch: IBranch;
}

const BranchSyncConfigurationModal = ({
  isOpen,
  onClose,
  projectConfiguration,
  branch
}: BranchSyncConfigurationModalProps) => {
  const [showCreateForm, setShowCreateForm] = useState(false);
  const syncConfigurations = projectConfiguration.dataModelSync.filter(
    (dataApplication) => dataApplication.sourceBranch === branch.name
  );

  useEffect(() => {
    if (syncConfigurations.length === 0) {
      setShowCreateForm(true);
    }
  }, [syncConfigurations, setShowCreateForm]);

  return (
    <Modal isOpen={isOpen} onClose={onClose} title={'Sync with Looker'} maxWidth="max-w-xl">
      {showCreateForm ? (
        <AddSyncConfiguration onClose={onClose} branch={branch} projectConfiguration={projectConfiguration} />
      ) : (
        <ListConfigurations
          projectConfiguration={projectConfiguration}
          setShowCreateForm={setShowCreateForm}
          onClose={onClose}
          branch={branch}
        />
      )}
    </Modal>
  );
};

interface ListConfigurationsProps {
  projectConfiguration: IProjectConfiguration;
  setShowCreateForm: (show: boolean) => void;
  onClose: () => void;
  branch: IBranch;
}

const ListConfigurations = ({ projectConfiguration, setShowCreateForm, onClose, branch }: ListConfigurationsProps) => {
  const [updateProjectConfiguration, { isLoading }] = useUpdateProjectConfigurationMutation();
  const projectId = useSelector(selectActiveProject);
  const accountId = useSelector(selectActiveAccountId);
  const [removingSyncConfiguration, setRemovingSyncConfiguration] = useState<IBranchSyncConfiguration | null>(null);
  const getProjectConfigurationQuery = useGetProjectConfigurationQuery({ projectId });
  const remove = async (syncConfiguration: IBranchSyncConfiguration) => {
    if (isLoading) {
      return;
    }
    const newProjectConfiguration = {
      ...projectConfiguration,
      dataModelSync: projectConfiguration.dataModelSync.filter(
        (dataApplication) => dataApplication !== syncConfiguration
      )
    };
    try {
      setRemovingSyncConfiguration(syncConfiguration);
      const { id: operationId } = await updateProjectConfiguration({
        projectId,
        configuration: newProjectConfiguration
      }).unwrap();
      const result = await waitForOperationToComplete(operationId, accountId);
      if (result.status === OperationStatus.Failed) {
        notify(`Failed to remove sync, check operation logs for more information`, 'error');
      } else {
        getProjectConfigurationQuery.refetch();
      }
    } catch (e) {
      notify(`Failed to remove sync: ${extractErrorMessage(e).message}`, 'error');
    } finally {
      setRemovingSyncConfiguration(null);
    }
  };

  return (
    <div>
      <div className="rounded-lg border border-slate-200 bg-slate-50 px-3 pt-3 text-sm">
        {projectConfiguration.dataModelSync.filter(syncConfiguration => syncConfiguration.sourceBranch === branch.name).map((syncConfiguration, index) => (
          <div key={index} className="flex items-center justify-between border-b border-slate-200 py-3 text-secondary">
            <div className="flex flex-col gap-2">
              <div className="flex items-center gap-2">
                <GithubIcon width="16" height="16" fill="#94A3B8" />
                {syncConfiguration.targetRepository}
              </div>
              <div className="flex items-center gap-2">
                <BranchIcon width="16" height="16" fill="#94A3B8" />
                {syncConfiguration.targetBranch}
              </div>
              <div className="flex items-center gap-2">
                <FolderIcon width="16" height="16" fill="#94A3B8" />
                {syncConfiguration.targetDirectory}
              </div>
              <div className="flex items-center gap-2">
                <CodeBracketIcon width="16" height="16" fill="#94A3B8" />
                {syncConfiguration.sqlDialect}
              </div>
              <div className="flex items-center gap-2">
                <ChevronDoubleUpIcon width="16" height="16" />
                {syncConfiguration.withPr ? 'With pull request' : 'Committed directly to branch' }
              </div>
            </div>
            <div
              className="flex cursor-pointer items-center gap-1 text-red-500 hover:text-red-600"
              onClick={() => remove(syncConfiguration)}
            >
              {removingSyncConfiguration === syncConfiguration ? (
                <>Loading...</>
              ) : (
                <>
                  <MinusCircleIcon width="20" height="20" />
                  Remove sync
                </>
              )}
            </div>
          </div>
        ))}
        <div
          className="my-3 flex cursor-pointer items-center gap-2 text-tertiary"
          onClick={() => setShowCreateForm(true)}
        >
          <PlusCircleIcon width="16" height="16" />
          Add sync
        </div>
      </div>
      <Button text="Done" type={ButtonTypes.primary} className="ml-auto mt-5 w-28" onClick={onClose} />
    </div>
  );
};
export default BranchSyncConfigurationModal;
