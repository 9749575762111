import { TailSpin } from 'react-loader-spinner';
import { GithubIcon } from '../../../assets/images/icons/DelphiIcons';
import Button from '../../../components/button/Button';
import { ButtonTypes } from '../../../components/button/types';
import { ChangeDiff, ChangeStatus, ChangeType, IChange, NewColumnChangeData, NewMeasureChangeData } from '../IChange';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useGenerateChangeDiffMutation } from '../../../services/changes/changes';
import { extractErrorMessage } from '../../../services/api';
import { DiffOperationResult } from './Types';
import { getFilenameFromPath } from '../../../utils/stringsUtils';
import FileDiff from '../../../components/FileDiff';
import { Alert } from '../../../components/Alert';
import { OperationStatus } from '../../operations/Operation';
import { LogsModal } from '../../account/AccountLogsModal';
import { SubnodeType } from '../../models/discover/INode';
import exportedVariables from '../../../../exportedVariables.json';
import { ArrowPathIcon } from '@heroicons/react/24/outline';
import { dateFormats, utcToLocal } from 'src/infrastructure/dateUtilities';
import { useSelector } from 'react-redux';
import { selectActiveAccountId } from 'src/infrastructure/state/slices/activeAccountSlice';
import { useGetAccountOperationQuery, useGetAccountOperationsQuery } from 'src/services/operations';

const getChangeDiffData = (change: IChange) => {
  if (change.changeType === ChangeType.NewColumn) {
    return {
      type: SubnodeType.Column.toLowerCase(),
      modelId: (change.changeData as NewColumnChangeData).modelId,
      name: (change.changeData as NewColumnChangeData).newColumnName
    };
  } else if (change.changeType === ChangeType.NewMeasure) {
    return {
      type: SubnodeType.Measure.toLowerCase(),
      modelId: (change.changeData as NewMeasureChangeData).modelId,
      name: (change.changeData as NewMeasureChangeData).newMeasureName
    };
  }
  return null;
};

const ChangeCodeDiff = ({ change, isActive }: { change: IChange; isActive: boolean }) => {
  const accountId = useSelector(selectActiveAccountId);
  const getOperations = useGetAccountOperationsQuery({ accountId, page: 1, pageSize: 100 });
  const operations = useMemo(() => (getOperations.data?.items || []).filter(o => o.name === 'generate_git_diff' && o.change_id === change.id), [change.id, getOperations.data?.items]);
  const lastFinishedDiffOperationId = operations.find(o => o.status === OperationStatus.Completed || o.status === OperationStatus.Failed)?.id;
  const getLastFinishedDiffOperation = useGetAccountOperationQuery({ accountId, operationId: lastFinishedDiffOperationId || 0 }, { skip: !lastFinishedDiffOperationId });
  const lastFinishedDiff = getLastFinishedDiffOperation.data;
  const isGeneratingDiff = getOperations.isFetching ||  operations.some(o => o.status === OperationStatus.Running || o.status === OperationStatus.Pending);
  const [generateDiff] = useGenerateChangeDiffMutation();
  const [generateDiffError, setGenerateDiffError] = useState('');
  const [showDiffOperationModal, setShowDiffOperationModal] = useState(false);
  const shouldShowDiff = [ChangeStatus.Open, ChangeStatus['Draft Rejected']].includes(change.status);
  const diff = lastFinishedDiff?.result ? getDiffFromOperationResult(lastFinishedDiff.result as DiffOperationResult) : null;

  const refreshDiff = useCallback(async () => {
    try {
      if (!change.id) {
        throw new Error('Change id is missing');
      }
      await generateDiff({ changeId: change.id, projectId: change.projectId }).unwrap();
    } catch (e) {
      setGenerateDiffError(extractErrorMessage(e).message);
    }
  }, [change, generateDiff]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (isGeneratingDiff) {
        getOperations.refetch();
      }
    }, 3000);
    return () => clearInterval(interval);
  }, [getOperations, isGeneratingDiff]);

  if (!isActive) {
    return null;
  }

  const normalizedChangeStrings = getChangeDiffData(change);

  return (
    <div>
      <div className="flex justify-between items-center mb-4">
        <div className="text-text-primary font-medium text-base">Code</div>
        {
          shouldShowDiff && (
            <>
              <div className="text-slate-400 text-sm ml-auto mr-2">{lastFinishedDiff?.start_time && `Last run on ${utcToLocal(lastFinishedDiff?.start_time, dateFormats.dateHoursAndMinutes)}`}</div>
              <Button isDisabled={isGeneratingDiff} isLoadingText='Refreshing' isLoading={isGeneratingDiff} icon={<ArrowPathIcon width="14" height="14" className={isGeneratingDiff ? 'animate-spin' : ''} />} type={ButtonTypes.secondary} text="Refresh" onClick={refreshDiff} />
            </>
          )
        }
        {change.pullRequestUrl && (
          <Button
            type={ButtonTypes.secondary}
            text="View in Github"
            icon={<GithubIcon width="14" height="14" fill="#334155" />}
            onClick={() => window.open(change.pullRequestUrl, '_blank')}
            className="ml-auto"
          />
        )}
      </div>
      {!diff && isGeneratingDiff && shouldShowDiff && (
        <div className="flex items-center">
          <TailSpin
            height="32"
            width="32"
            color={exportedVariables.primary}
            ariaLabel="tail-spin-loading"
            radius="1"
            wrapperStyle={{}}
            wrapperClass="mr-2"
            visible={true}
          />
          Generating diff...
        </div>
      )}
      {!shouldShowDiff && (
        <Alert
          text="To view the changes to the dbt repository, select the GitHub link to be directed to the associated pull request."
          type="info"
          className="mt-2"
        />
      )}
      {lastFinishedDiff && shouldShowDiff && diff?.length ? diff.map((file, index) => <FileDiff key={index} file={file} index={index} />) : null}
      {lastFinishedDiff && shouldShowDiff && lastFinishedDiff?.status !== OperationStatus.Failed && !diff?.length && normalizedChangeStrings ?
        (<div>
          <Alert text={`A ${normalizedChangeStrings.type} with the name ${normalizedChangeStrings.name} already exists in the model ${normalizedChangeStrings.modelId}. If this is indeed a different ${normalizedChangeStrings.type}, please edit the name in the change proposals and refresh the page to generate the code diff.`} type="warning" />
        </div>)
        : null
      }
      {lastFinishedDiff?.status === OperationStatus.Failed && (
        <>
          <div
            onClick={() => {
              setShowDiffOperationModal(true);
            }}
            className="cursor-pointer"
          >
            <Alert text={`Failed to generate diff - click to view operation log`} type="error" />
          </div>
          <LogsModal
            operation={lastFinishedDiff}
            isOpen={showDiffOperationModal}
            onClose={() => setShowDiffOperationModal(false)}
          />
        </>
      )}
      {generateDiffError && (
        <Alert text={`Failed to generate diff: ${extractErrorMessage(generateDiffError).message}`} type="error" />
      )}
    </div>
  );
};

const getDiffFromOperationResult = (operationResult: DiffOperationResult): ChangeDiff[] => {
  return operationResult.diff.parsed_diff.map((file) => ({
    fileName: getFilenameFromPath(file.new_path || ''),
    oldCode: atob(file.old_content_base64 || ''),
    newCode: atob(file.new_content_base64 || '')
  }));
};

export default ChangeCodeDiff;
