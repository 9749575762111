import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import Input from "src/components/form/Input";

type SearchProps = {
	search: string;
	setSearch: (value: string) => void;
	className?: string;
	inputClassName?: string;
	placeholder?: string
}

export const Search = ({ search, setSearch, className, inputClassName, placeholder }: SearchProps) => {
	return (
		<div className={`w-60 ${className}`}>
			<Input
				placeholder={placeholder || "Search"}
				value={search}
				onInputChange={e => setSearch(e.target.value)}
				rounded="rounded-full"
				inputClassName={inputClassName}
				height="h-8"
				icon={<MagnifyingGlassIcon width="14" height="14" />}
			/>
		</div>
	);
};