import { SyncJob, SyncTarget } from "src/features/dataModelSync/types";

export const getSyncJobValidationError = (syncJob: SyncJob, syncTarget: SyncTarget | null): string | null => {
    if (!syncJob.name) {
        return 'Name is required';
    }

    if (!syncJob.configuration.dbtProjectName) {
        return 'dbt project name is required';
    }

    if (syncTarget) {
        if (!syncJob.configuration.targetBranch) {
            return 'Target branch is required';
        }
        if (!syncJob.configuration.targetDirectory) {
            return 'Target directory is required';
        }
        if (syncJob.configuration.automaticallySyncDataModel && !syncJob.configuration.eunoProjectId) {
            return 'Euno project must be selected when automatically syncing data model';
        }
    }
    return null;
};
