import toast from 'react-hot-toast';
import { events, trackEvent } from 'src/infrastructure/analytics';

type notificationTypes = 'success' | 'error';

export const notify = (message: string, type: notificationTypes, duration?: number) => {
    if (type === 'error') {
        trackEvent(events.errorToasterShown, { message });
    }
    toast[type](message, {
        position: "bottom-left",
        duration: duration || 5000
    });
};

export const customToast = toast;