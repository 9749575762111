enum BuildLifecycleState {
    Uploading = 'uploading',
    UploadIncomplete = 'upload_incomplete',
    PendingProcessing = 'pending_processing',
    Processing = 'processing',
    Processed = 'processed',
    Completed = 'completed',
    Failed = 'failed'
}

export default BuildLifecycleState;