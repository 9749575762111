import Input from "src/components/form/Input";
import Checkbox from "src/components/form/Checkbox";
import { GenericIntegrationType } from "src/services/integrations/types";


type FieldType = {
  title: string,
  label: string,
  placeholder?: string,
  sensitive?: boolean,
  optional?: boolean,
  component: typeof Input | typeof Checkbox
}

export const FIELDS_BY_TYPE:{[key in GenericIntegrationType]: FieldType[]} = {
  looker: [
    { title: "host", label: "Host", placeholder: "Enter host URL", component: Input },
    { title: "client_id", label: "Client ID", placeholder: "Enter client ID", component: Input },
    { title: "client_secret", label: "Client Secret", placeholder: "Enter client Secret", sensitive: true, component: Input }
  ],
  tableau: [
    { title: "connect_uri", label: "Connection URL", placeholder: "Enter connection URL", component: Input },
    { title: "site", label: "Site", placeholder: "Enter site", component: Input },
    { title: "token_name", label: "Token name", placeholder: "Enter token name", component: Input },
    { title: "token_value", label: "Token value", placeholder: "Enter token value", sensitive: true, component: Input },
  ],
  snowflake: [
    { title: "host", label: "Host", placeholder: "Enter host", component: Input },
    { title: "user", label: "Username", placeholder: "Enter username", component: Input },
    { title: "password", label: "Password", placeholder: "Enter password", sensitive: true, component: Input },
    { title: "role", label: "Role", placeholder: "Enter role", optional: true, component: Input },
    { title: "warehouse", label: "Warehouse", placeholder: "Enter warehouse", optional: true, component: Input },
    { title: "ssl_verify", label: "Skip SSL verification", component: Checkbox }
  ],
  dbt_core: [
    { title: "build_target", label: "Build target", placeholder: "Enter target", component: Input },
  ]
};

export const ADVANCED_FIELDS_BY_TYPE:{[key in GenericIntegrationType]: FieldType[]} = {
  looker: [
    { title: "lookml_project_include_pattern", label: "Lookml project includes", placeholder: "Enter pattern", component: Input },
    { title: "lookml_model_include_pattern", label: "Lookml model includes", placeholder: "Enter pattern", component: Input },
    { title: "crawl_lookml_only", label: "Crawl lookml only", component: Checkbox }
  ],
  tableau: [
    { title: "override_url_for_links", label: "Override URL for link", placeholder: "https://us-west-2b.online.tableau.com/", component: Input },
    { title: "ssl_verify", label: "Skip SSL verification", component: Checkbox }
  ],
  snowflake: [
    { title: "table_to_use_for_query_history", label: "Query history table", placeholder: "Enter table name", component: Input },
    { title: "extract_views", label: "Extract views", component: Checkbox },
    { title: "extract_tableau_usage", label: "Extract tableau usage", component: Checkbox },
    { title: "use_snowflake_database", label: "use snowflake system database to collect views", component: Checkbox }
  ],
  dbt_core: [
    { title: "repository_url", label: "Source repository URL", placeholder: "Enter url", component: Input },
    { title: "repository_branch", label: "Source repository branch", placeholder: "Enter branch", component: Input },
    { title: "dbt_project_root_directory_in_repository", label: "Relative directory of the DBT project", placeholder: "Enter your directory", component: Input },
  ]
};
