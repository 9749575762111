import { KeyIcon } from "@heroicons/react/24/solid";
import { MagnifyingGlassIcon } from "@heroicons/react/24/solid";
import { useState } from "react";
import { useSelector } from "react-redux";
import { SparklesLeftArrowIcon } from "src/assets/images/icons/DelphiIcons";
import Input from "src/components/form/Input";
import { List } from "src/components/List";
import { ComponentLoader } from "src/components/loaders/ComponentLoader";
import { IExpandedNode, ISubnode, ISuperficialNode, NodeType, nodeTypePlurals, SubnodeType, subnodeTypePlurals } from "src/features/models/discover/INode";
import { NodeIcon } from "src/features/models/discover/NodeIcon";
import { sortListAlphabeticallyByName } from "src/features/models/discover/resourceSidepane/sortListAlphabeticallyByName";
import { selectActiveAccountId } from "src/infrastructure/state/slices/activeAccountSlice";
import { useGetDataModelResourcesQuery } from "src/services/nodes/nodes";

type SchemaFilters = {
    onlyCalculatedFields: boolean;
    onlyNonTrivialFields: boolean;
}

const defaultSchemaFilters: SchemaFilters = {
    onlyCalculatedFields: false,
    onlyNonTrivialFields: false,
};

export const ResourceSidepaneSchemaTab = ({ resource, setResourceId }: { resource: IExpandedNode, setResourceId: (id: string) => void }) => {
    const [inputSearch, setInputSearch] = useState<string>('');
    const [schemaFilters] = useState<SchemaFilters>(defaultSchemaFilters);
    const accountId = useSelector(selectActiveAccountId);
    const eql = `((parent_container='${resource.id}') OR (type='dbt_metric' AND has upstream(uri='${resource.id}')))`;
    const getSubresources = useGetDataModelResourcesQuery({ accountId, page: 1, pageSize: 1000, eql, withSubResources: true });
    const subResources = getSubresources.data?.items || [];
    const subnodesThatAreNotInSubresources = resource.subnodes.filter(n => !getSubresources.data?.items?.find(r => r.id.toLowerCase() === n.id.toLowerCase()) && n.type !== SubnodeType.Metric);
    const filteredSubnodes = filterSubnodes(subnodesThatAreNotInSubresources, schemaFilters, inputSearch,);
    const subnodeTypes = Array.from(new Set(subnodesThatAreNotInSubresources.map(n => n.type))).sort();
    const filteredSubresources = filterSubresources(subResources, schemaFilters, inputSearch);
    const subresourcesTypes = Array.from(new Set(subResources.map(n => n.type))).sort();
    const total = filteredSubresources.length + subnodesThatAreNotInSubresources.length; 
    
    if (getSubresources.isFetching) {
        return <ComponentLoader className="mt-40" />;
    }

    return (
        <div className="bg-surface-light flex-1">
            <div className="p-4 flex-1 overflow-y-auto max-h-[80vh] flex flex-col gap-4 h-full">
                <div className="flex mx-2 items-center gap-2">
                    <div className="text-secondary font-semibold">{total} items</div>
                    <Input
                        placeholder="Search"
                        value={inputSearch}
                        onInputChange={e => setInputSearch(e.target.value)}
                        border="border-primary"
                        rounded="rounded-full"
                        className="!w-60 ml-auto"
                        height="h-8"
                        icon={<MagnifyingGlassIcon width="16" height="16" className="text-slate-400" />}
                    />
                    {/* <SchemaFiltersMenu schemaFilters={schemaFilters} setSchemaFilters={setSchemaFilters} /> */}
                </div>
                <div className="flex flex-col gap-2">
                    {
                        subnodeTypes.map(type => <SubnodeCategory key={type} type={type} subnodes={filteredSubnodes} parentResource={resource} setResourceId={setResourceId} />)
                    }
                    {
                        subresourcesTypes.map(type => <SubresourceCategory key={type} type={type} resources={filteredSubresources} setResourceId={setResourceId} />)
                    }
                </div>
            </div>
        </div>
    );
};

const SubresourceCategory = ({ type, resources, setResourceId }: { type: NodeType, resources: ISuperficialNode[], setResourceId: (id: string) => void }) => {
    const typeItems = resources.filter(n => n.type === type).sort(sortListAlphabeticallyByName);
    return (
        <List
            key={type}
            label={nodeTypePlurals[type]}
            items={
                typeItems.map(r => ({
                    title: (
                        <div className="flex items-center gap-2">
                            {r.name}
                            {
                                r.isCalculated && <div className="rounded text-sm py-0.5 px-1.5 text-slate-500 bg-slate-200">Calculated field</div>
                            }
                            {
                                r.hasProposals && <div className="rounded p-1 text-primary bg-lilac-50"><SparklesLeftArrowIcon width="12" height="12" /></div>
                            }
                        </div>
                    ),
                    value: (r.nativeSubType || '').toUpperCase(),
                    onClick: () => setResourceId(r.id),
                    icon: <NodeIcon type={r.type} iconSize={14} />
                }))
            }
            isCollapsible={true}
            defaultCollapsed={false}
            headerActions={<div className="ml-auto text-slate-400">{typeItems.length}</div>}
        />
    );
};

const SubnodeCategory = ({ type, subnodes, parentResource, setResourceId }: { type: SubnodeType, subnodes: ISubnode[], parentResource: IExpandedNode, setResourceId: (id: string) => void }) => {
    const typeItems = subnodes.filter(n => n.type === type).sort(sortListAlphabeticallyByName);
    const unClickableTypes = [SubnodeType.Entity, SubnodeType.TableCalculation];
    return (
        <List
            key={type}
            label={subnodeTypePlurals[type]}
            items={
                typeItems.map(r => ({
                    title: (
                        <div className="flex items-center gap-2">
                            {r.name}
                            {
                                r.subType === 'primary' && <KeyIcon width="14" height="14" className="text-slate-400" />
                            }
                        </div>
                    ),
                    value: (r.subType || '').toUpperCase(),
                    icon: <NodeIcon type={parentResource.type} subnodeType={r.type} iconSize={14} />,
                    onClick: !unClickableTypes.includes(r.type) ? () => setResourceId(r.id) : undefined,
                }))
            }
            isCollapsible={true}
            defaultCollapsed={false}
            headerActions={<div className="ml-auto text-slate-400">{typeItems.length}</div>}
        />
    );
};

//Commented out until we migrate to resources in schema instead of subnodes
// type SchemaFiltersProps = {
//     schemaFilters: SchemaFilters;
//     setSchemaFilters: (filters: SchemaFilters) => void;
// }

// const SchemaFiltersMenu = ({ schemaFilters, setSchemaFilters }: SchemaFiltersProps) => {
//     const [isOpen, setIsOpen] = useState<boolean>(false);
//     const ref = useRef<HTMLDivElement>(null);
//     useClickAway(ref, () => setIsOpen(false));
//     const items = [
//         {
//             name: 'Only calcualated fields',
//             isChecked: schemaFilters.onlyCalculatedFields,
//             onClick: () => setSchemaFilters({ ...schemaFilters, onlyCalculatedFields: !schemaFilters.onlyCalculatedFields }),
//         },
//         {
//             name: 'Only non-trivial fields',
//             isChecked: schemaFilters.onlyNonTrivialFields,
//             onClick: () => setSchemaFilters({ ...schemaFilters, onlyNonTrivialFields: !schemaFilters.onlyNonTrivialFields }),
//         }
//     ];
//     return (
//         <div className="relative" ref={ref}>
//             <div className="cursor-pointer hover:bg-slate-50 text-tertiary rounded border border-border bg-white p-1" onClick={() => setIsOpen(!isOpen)}>
//                 <FilterIcon width="14" height="14" />
//             </div>
//             {
//                 isOpen && (
//                     <div className="absolute top-0 right-0 mt-8 bg-white rounded-lg shadow text-text-primary">
//                         {
//                             items.map(item => (
//                                 <div className="font-normal px-3 py-2 gap-8 whitespace-nowrap flex justify-between" key={item.name}>
//                                     {
//                                         item.name
//                                     }
//                                     <Checkbox value={item.isChecked} setValue={item.onClick} />
//                                 </div>
//                             ))
//                         }
//                     </div>
//                 )
//             }
//         </div>
//     );
// };

const filterSubnodes = (subnodes: ISubnode[], filters: SchemaFilters, search: string): ISubnode[] => {
    return subnodes.filter(n => {
        if (filters.onlyCalculatedFields) return false;
        if (filters.onlyNonTrivialFields) return false;
        if (search && !n.name.toLowerCase().includes(search.toLowerCase())) return false;
        return true;
    });
};

const filterSubresources = (subresources: ISuperficialNode[], filters: SchemaFilters, search: string): ISuperficialNode[] => {
    return subresources.filter(n => {
        if (filters.onlyCalculatedFields && !n.isCalculated) return false;
        if (filters.onlyNonTrivialFields && n.isTrivialSql) return false;
        if (search && !n.name.toLowerCase().includes(search.toLowerCase())) return false;
        return true;
    });
};