import { SyncJob, SyncRun, SyncTarget } from 'src/features/dataModelSync/types';
import api from '../api';
import { transformBackendSyncJobToLocal, transformBackendSyncRunToLocal, transformBackendSyncTargetToLocal, transformLocalSyncJobToBackend, transformLocalSyncTargetToBackend } from 'src/services/actions/transformers';
import { BackendActionRun, BackendSyncJobResponse, BackendSyncTarget } from 'src/services/actions/types';

export const operationsApi = api.injectEndpoints({
    endpoints: (build) => ({
        cancelRun: build.mutation<void, { accountId: number; runId: number }>({
            query: ({ accountId, runId }) => ({
                url: `accounts/${accountId}/actions/runs/${runId}`,
                method: 'DELETE'
            }),
            invalidatesTags: ['Actions']
        }),
        getArtifacts: build.query<string, { accountId: number; runId: number; artifact: string }>({
            providesTags: ['Actions'],
            query: ({ accountId, runId, artifact }) => {
                return {
                    url: `accounts/${accountId}/actions/runs/${runId}/artifacts/${artifact}`,
                    method: 'GET',
                    responseHandler: async (response) => {
                        const blob = await response.blob();
                        const url = URL.createObjectURL(blob);
                        const link = document.createElement('a');
                        link.href = url;
                        link.download = artifact;
                        document.body.appendChild(link);
                        link.click();
                        document.body.removeChild(link);
                        URL.revokeObjectURL(url);
                    },
                    cache: "no-cache",
                };
            }
        }),
        getRuns: build.query<{ items: SyncRun[], total: number }, { accountId: number, jobId?: number, page: number, pageSize: number }>({
            query: ({ accountId, jobId, page, pageSize }) => {
                const url = `accounts/${accountId}/actions/runs`;
                const params = new URLSearchParams();
                params.append('page', page.toString());
                params.append('page_size', pageSize.toString());
                if (jobId) {
                    params.append('job_id', jobId.toString());
                }
                return `${url}?${params.toString()}`;
            },
            providesTags: ['Actions'],
            transformResponse: ({ items, total }: { items: BackendActionRun[], total: number }) => ({
                items: items.map(transformBackendSyncRunToLocal),
                total
            })
        }),
        createJob: build.mutation<number, { accountId: number, job: SyncJob }>({
            query: ({ accountId, job }) => ({
                url: `accounts/${accountId}/actions/jobs`,
                method: 'POST',
                body: transformLocalSyncJobToBackend(job)
            }),
            invalidatesTags: ['Actions'],
            transformResponse: ({ id }: { id: number }) => id
        }),
        getJobs: build.query<SyncJob[], { accountId: number }>({
            query: ({ accountId }) => `accounts/${accountId}/actions/jobs`,
            providesTags: ['Actions'],
            transformResponse: (jobs: BackendSyncJobResponse[]) => jobs.map(transformBackendSyncJobToLocal)
        }),
        getJob: build.query<SyncJob, { accountId: number; jobId: number }>({
            query: ({ accountId, jobId }) => `accounts/${accountId}/actions/jobs/${jobId}`,
            providesTags: ['Actions'],
            transformResponse: (job: BackendSyncJobResponse) => transformBackendSyncJobToLocal(job)
        }),
        updateJob: build.mutation<void, { accountId: number; jobId: number; job: SyncJob }>({
            query: ({ accountId, jobId, job }) => ({
                url: `accounts/${accountId}/actions/jobs/${jobId}`,
                method: 'PATCH',
                body: transformLocalSyncJobToBackend(job)
            }),
            invalidatesTags: ['Actions'],
        }),
        runJob: build.mutation<void, { accountId: number; jobId: number }>({
            query: ({ accountId, jobId }) => ({
                url: `accounts/${accountId}/actions/jobs/${jobId}/run_now`,
                method: 'POST'
            }),
            invalidatesTags: ['Actions']
        }),
        retryRun: build.mutation<void, { accountId: number; runId: number }>({
            query: ({ accountId, runId }) => ({
                url: `accounts/${accountId}/actions/runs/${runId}/reopen`,
                method: 'POST'
            }),
            invalidatesTags: ['Actions']
        }),
        archiveJob: build.mutation<void, { accountId: number; jobId: number }>({
            query: ({ accountId, jobId }) => ({
                url: `accounts/${accountId}/actions/jobs/${jobId}`,
                method: 'DELETE'
            }),
            invalidatesTags: ['Actions']
        }),
        getTarget: build.query<SyncTarget, { accountId: number, targetId: number }>({
            query: ({ accountId, targetId }) => `accounts/${accountId}/actions/targets/${targetId}`,
            providesTags: ['Actions'],
            transformResponse: transformBackendSyncTargetToLocal
        }),
        getTargets: build.query<SyncTarget[], { accountId: number }>({
            query: ({ accountId }) => `accounts/${accountId}/actions/targets`,
            providesTags: ['Actions'],
            transformResponse: (targets: BackendSyncTarget[]) => targets.map(transformBackendSyncTargetToLocal)
        }),
        createTarget: build.mutation<number, { accountId: number; target: SyncTarget }>({
            query: ({ accountId, target }) => ({
                url: `accounts/${accountId}/actions/targets`,
                method: 'POST',
                body: transformLocalSyncTargetToBackend(target)
            }),
            invalidatesTags: ['Actions'],
            transformResponse: ({ id }: { id: number }) => id
        }),
        updateTarget: build.mutation<void, { accountId: number; targetId: number; target: SyncTarget }>({
            query: ({ accountId, targetId, target }) => ({
                url: `accounts/${accountId}/actions/targets/${targetId}`,
                method: 'PATCH',
                body: transformLocalSyncTargetToBackend(target)
            }),
            invalidatesTags: ['Actions']
        }),
        getRunReport: build.query<string, { accountId: number; runId: number }>({
            providesTags: ['Actions'],
            query: ({ accountId, runId }) => ({ url: `accounts/${accountId}/actions/runs/${runId}/run_report`, headers: { 'Accept': 'text/plain' } ,
            responseHandler: response => response.text() }),
        })
    })
});

export const {
    useCancelRunMutation,
    useLazyGetArtifactsQuery,
    useGetRunsQuery,
    useCreateJobMutation,
    useGetJobsQuery,
    useGetJobQuery,
    useUpdateJobMutation,
    useRunJobMutation,
    useRetryRunMutation,
    useArchiveJobMutation,
    useGetTargetsQuery,
    useLazyGetTargetsQuery,
    useLazyGetTargetQuery,
    useCreateTargetMutation,
    useUpdateTargetMutation,
    useGetRunReportQuery
} = operationsApi;
