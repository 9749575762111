import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import Layout from "src/components/layout/Layout";
import TopBar from "src/components/layout/TopBar";
import { Tabs } from "src/components/Tabs";
import { DataModelSyncJobs } from "src/features/dataModelSync/syncJobs/DataModelSyncJobs";
import { DataModelSyncRuns } from "src/features/dataModelSync/syncRuns/DataModelSyncRuns";

enum Tab {
    RUNS = "runs",
    JOBS = "jobs",
}

export const DataModelSyncPage = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const [activeTab, setActiveTab] = useState<Tab>(Tab.RUNS);

    useEffect(() => {
        const tab = searchParams.get("tab");
        if (tab) {
            setActiveTab(tab as Tab);
        }
    }, [searchParams]);

    const updateTab = (tab: Tab) => {
        setSearchParams({ tab });
    };

    return (
        <Layout>
            <TopBar>
                <Tabs className="w-fit mx-auto" items={[
                    {
                        onClick: () => updateTab(Tab.RUNS),
                        text: 'Data sync runs',
                        isActive: activeTab === Tab.RUNS,
                    },
                    {
                        onClick: () => updateTab(Tab.JOBS),
                        text: 'Data sync jobs',
                        isActive: activeTab === Tab.JOBS,
                    }
                ]} />
            </TopBar>
            <div className="bg-slate-50 h-full">
                {
                    activeTab === Tab.RUNS && <DataModelSyncRuns />
                }
                {
                    activeTab === Tab.JOBS && <DataModelSyncJobs />
                }
            </div>
        </Layout>
    );
};
