import { PlayIcon } from "@heroicons/react/24/solid";
import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import { useState } from "react";
import { useSelector } from "react-redux";
import Input from "src/components/form/Input";
import { IExpandedNode, ISuperficialNode, nodeNameMap } from "src/features/models/discover/INode";
import { NodeIcon } from "src/features/models/discover/NodeIcon";
import { selectActiveAccountId } from "src/infrastructure/state/slices/activeAccountSlice";
import { useGetDataModelResourcesQuery } from "src/services/nodes/nodes";
import { sortListAlphabeticallyByName } from "src/features/models/discover/resourceSidepane/sortListAlphabeticallyByName";

type ResourceSidepaneRelationshipsTabProps = {
    resource: IExpandedNode;
    setResourceId: (id: string) => void;
};

export const ResourceSidepaneRelationshipsTab = ({ resource, setResourceId }: ResourceSidepaneRelationshipsTabProps) => {
    const [inputSearch, setInputSearch] = useState<string>('');
    const accountId = useSelector(selectActiveAccountId);
    const getUpstreamResources = useGetDataModelResourcesQuery({ accountId, page: 1, pageSize: 20, eql: `has downstream(uri='${resource.id}', 1)` });
    const getDownstreamResources = useGetDataModelResourcesQuery({ accountId, page: 1, pageSize: 20, eql: `has upstream(uri='${resource.id}', 1)` });
    const upstreamResources = (getUpstreamResources.data?.items || []).filter(r => r.name.toLocaleLowerCase().includes(inputSearch.toLocaleLowerCase()));
    const downstreamResources = (getDownstreamResources.data?.items || []).filter(r => r.name.toLocaleLowerCase().includes(inputSearch.toLocaleLowerCase()));

    return (
        <div className="flex-1 bg-surface-light">
            <div className="p-4 h-full overflow-y-auto max-h-[80vh]">
                <div className="flex justify-between mx-2 items-center">
                    <div className="text-secondary font-semibold">{upstreamResources.length + downstreamResources.length} items</div>
                    <Input
                        placeholder="Search"
                        value={inputSearch}
                        onInputChange={e => setInputSearch(e.target.value)}
                        border="border-primary"
                        rounded="rounded-full"
                        className="!w-60"
                        height="h-8"
                        icon={<MagnifyingGlassIcon width="16" height="16" className="text-slate-400" />}
                    />
                </div>
                <div className="flex flex-col gap-2 mt-4">
                    <Group setResourceId={setResourceId} resources={upstreamResources} title="Upstream" />
                    <Group setResourceId={setResourceId} resources={downstreamResources} title="Downstream" />
                </div>
            </div>
        </div>
    );
};

type GroupProps = {
    title: string;
    resources: ISuperficialNode[];
    setResourceId: (id: string) => void;
};

export const Group = ({ title, resources, setResourceId }: GroupProps) => {
    const [isExpanded, setIsExpanded] = useState<boolean>(true);
    return (
        <div className="rounded-lg border border-border">
            <div className="p-2 flex rounded-t-lg gap-1 bg-gradient-to-b from-lilac-50 items-center">
                <div className="flex-1 flex gap-1 items-center cursor-pointer" onClick={() => setIsExpanded(!isExpanded)}>
                    <div className="w-fit">
                        {
                            isExpanded ? <PlayIcon width="14" height="14" className="text-tertiary rotate-90" /> : <PlayIcon width="14" height="14" className="text-tertiary" />
                        }
                    </div>
                    <span className="text-secondary font-base">{title}</span>
                </div>
                <span className="text-slate-600">{resources.length} direct</span>
            </div>
            {
                isExpanded && (
                    <div className="rounded-b-lg overflow-y-auto max-h-96">
                        {
                            resources.sort(sortListAlphabeticallyByName).map((r, i) => (
                                <ResourceRowItem
                                    setResourceId={setResourceId}
                                    isLastItem={i === resources.length - 1}
                                    key={r.id}
                                    resource={r}
                                    index={i}
                                />
                            ))
                        }
                    </div>
                )
            }
        </div>
    );
};

type ResourceRowItemProps = {
    resource: ISuperficialNode;
    isLastItem: boolean;
    setResourceId: (id: string) => void;
    index: number;
}

const ResourceRowItem = ({ resource, isLastItem, setResourceId, index }: ResourceRowItemProps) => {
    const onClick = () => {
        setResourceId(resource.id);
    };

    return (
        <div className={`flex items-center gap-2 py-2 px-2 border-border cursor-pointer ${index % 2 === 0 ? 'bg-white hover:bg-slate-50' : 'bg-slate-50 hover:bg-slate-100'} ${isLastItem ? 'rounded-b-lg' : 'border-b'}`} onClick={onClick}>
            <div className="w-fit">
                <NodeIcon type={resource.type} iconSize={14} greyVersion={true} />
            </div>
            <div className="text-text-primary">{resource.name}</div>
            <div className="text-slate-400 text-sm ml-auto">{nodeNameMap.get(resource.type)}</div>
        </div>
    );
};