import { useMemo, useState } from "react";
import { useSelector } from "react-redux";
import Select from "src/components/form/Select";
import Table from "src/components/Table/Table";
import { syncRunsTableColumns } from "src/features/dataModelSync/syncRuns/syncRunsTableColumns";
import { SyncRun } from "src/features/dataModelSync/types";
import { selectActiveAccountId } from "src/infrastructure/state/slices/activeAccountSlice";
import { useGetJobsQuery, useGetRunsQuery } from "src/services/actions/actions";
import { Option } from "src/components/form/Select";
import { useSearchParams } from "react-router-dom";

export const DataModelSyncRuns = () => {
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const accountId = useSelector(selectActiveAccountId);
    const getSyncJobs = useGetJobsQuery({ accountId });
    const [searchParams, setSearchParams] = useSearchParams();
    const selectedJob = useMemo(() => Number(searchParams.get('jobId') || 0), [searchParams]);
    const getSyncRuns = useGetRunsQuery({ accountId, page, pageSize, jobId: selectedJob || undefined });

    const setSelectedJob = (jobId: number) => {
        searchParams.set('jobId', jobId.toString());
        setSearchParams(searchParams);
    };

    return (
        <div className="p-2">
            <div className="w-fit ml-auto my-2">
                <Select
                    value={selectedJob}
                    onChange={(option: Option | Option[]) => setSelectedJob(Number((option as Option).value))}
                    options={[{ label: 'All jobs', value: 0 }, ...getSyncJobs.data?.map(job => ({ label: job.name, value: job.id })) || []]}
                    placeholder="Filter by sync job"
                    className="-ml-40"
                />
            </div>
            <Table<SyncRun>
                isLoading={getSyncRuns.isFetching}
                data={getSyncRuns.data?.items || []}
                columns={syncRunsTableColumns}
                pagination={{ page, pageSize, total: getSyncRuns.data?.total || 0, setPage, setPageSize }}
                maxBodyHeight='80vh'
                fixedHeight={true}
            />
        </div>
    );
};