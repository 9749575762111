type PageTabsProps = {
    withUnderscore?: boolean;
    tabs: {
        name: string;
        isActive: boolean;
        onClick: () => void;
    }[]
}

export const PageTabs = ({ tabs, withUnderscore = false }: PageTabsProps) => {
    const wrapperClassNames = withUnderscore ? 'self-start' : 'text-text-primary w-fit mx-auto';
    return (
        <div className={`flex cursor-pointer gap-1 ${wrapperClassNames}`}>
            {
                tabs.map((tab) => {
                    const tabClassNames = withUnderscore ?
                        `${tab.isActive ? 'text-lilac-700 border-b border-b-lilac-700' : ''}` :
                        `rounded-md ${tab.isActive ? 'bg-lilac-100' : 'hover:bg-lilac-50'}`;
                    return (
                        <div
                            key={tab.name}
                            data-test-id={`${tab.name}-tab`}
                            className={`py-1 px-2 capitalize select-none ${tabClassNames}`}
                            onClick={tab.onClick}>
                            {tab.name}
                        </div>
                    );
                })
            }
        </div>
    );
};