import { NavLink } from 'react-router-dom';
import { useFlags } from "launchdarkly-react-client-sdk";
import { DataModelIcon, ServerIcon, SparklesLeftArrowIcon } from 'src/assets/images/icons/DelphiIcons';
import { ArrowPathIcon, ChartBarIcon, Cog6ToothIcon, HomeIcon } from '@heroicons/react/24/outline';

const baseNavItems = [
  {
    to: '/',
    Icon: <HomeIcon width="20" height="20" />,
    label: 'Home'
  },
  {
    to: `/data-model`,
    Icon: <DataModelIcon width="20" height="20" fill="#0A225C" />,
    label: 'Data model'
  },
  {
    to: `/dashboards`,
    Icon: <ChartBarIcon width="20" height="20" />,
    label: 'Dashboards'
  },
  {
    to: `/account`,
    Icon: <Cog6ToothIcon width="20" height="20" />,
    label: 'Account settings'
  }
];

export const MainNavigation = () => {
  const { showSourcesTabInNavigation, showDataModelSyncTab, hideShiftLeft } = useFlags();
  const copiedNavItems = [...baseNavItems];

  if (!hideShiftLeft) {
    copiedNavItems.splice(1, 0, {
      to: `/model-changes`,
      Icon: <SparklesLeftArrowIcon width="20" height="20" fill="#0A225C" />,
      label: 'Model changes'
    });
  }
  if (showSourcesTabInNavigation) {
    copiedNavItems.splice(-1, 0, {
      to: '/sources',
      Icon: <ServerIcon width="20" height="20" />,
      label: 'Sources'
    });
  }
  if (showDataModelSyncTab) {
    {
      copiedNavItems.splice(-1, 0, {
        to: '/data-model-sync',
        Icon: <ArrowPathIcon width="20" height="20" />,
        label: 'Data model sync'
      });
    }
  }

  return (
    <div className="px-2 w-60">
      <nav>
        {copiedNavItems.map((item) => {
          return (
            <NavLink
              key={item.label}
              to={item.to}
              className={({ isActive }) =>
                `mb-1 flex h-12 cursor-pointer text-text-primary items-center rounded-lg ${isActive ? 'bg-slate-100' : ''
                } px-2 hover:bg-slate-100`
              }>
              {item.Icon}
              <span className="ml-3">{item.label}</span>
            </NavLink>
          );
        })}
      </nav>
    </div>
  );
};
