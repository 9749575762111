import { SparklesIcon } from '@heroicons/react/24/solid';
import { ChangeType, SuperficialPat } from '../IChange';
import Table from '../../../components/Table/Table';
import { TextWithEllipsisAndTooltip } from '../../../components/TextWithEllipsisAndTooltip';
import { DbtCloudIcon, LookerIcon } from '../../../assets/images/icons/DelphiIcons';
import { useGetPatsQuery, useLazyGetPatQuery } from '../../../services/changes/changes';
import CreateChangeSidepane from '../createChangeSidepane/CreateChangeSidepane';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { selectActiveAccountId } from '../../../infrastructure/state/slices/activeAccountSlice';
import { dateFilterToTimeRange, DateFilterValue } from './filters/dateFilter';
import { DataModelFilters } from './filters/DataModelFilters';
import { DataModelFilter } from './Types';
import { dateFormats, unixToLocal } from '../../../infrastructure/dateUtilities';
import { mapLocalChangeTypeToBackendChangeType } from '../../../services/changes/transformers';
import { FilterValue } from '../../../components/Table/TableFilters/types';

export const PatsList = () => {
  const [getPatQuery, selectedPat] = useLazyGetPatQuery();
  const accountId = useSelector(selectActiveAccountId);
  const [showCreateChangeSidepane, setShowCreateChangeSidepane] = useState(false);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(25);
  const [filterValues, setFilterValues] = useState<Record<string, FilterValue>>({});
  const [searchInput, setSearchInput] = useState('');
  const [projectId, setProjectId] = useState<number | null>(null);
  const getPatsQuery = useGetPatsQuery({
    eql: [
      `has_shift_left_potential is true AND shift_left_project='${projectId}'`,
      createEqlFromFilters(filterValues, searchInput),
    ].filter(q => !!q).join(' AND '),
    accountId,
    page: page,
    pageSize,
  }, { skip: !projectId});

  const onRowClicked = (row: unknown) => {
    const pat = row as SuperficialPat;
    setShowCreateChangeSidepane(true);
    getPatQuery({ accountId, uri: pat.uri });
  };

  return (
    <div>
      <DataModelFilters
        tableName='pats-table'
        onFilterValuesChange={setFilterValues}
        showSort={false}
        searchInput={searchInput}
        setSearchInput={setSearchInput}
        selectedProjectId={projectId}
        setSelectedProjectId={setProjectId}
      />
      <div className="mt-4 max-w-[78vw]">
        <Table isLoading={getPatsQuery.isLoading || selectedPat.isLoading} data={getPatsQuery.data?.items || []} columns={tableColumns} onRowClicked={onRowClicked} maxBodyHeight='72vh' pagination={{ page, pageSize, total: getPatsQuery.data?.total || 0, setPage, setPageSize }} />
      </div>
      {showCreateChangeSidepane && !selectedPat.isFetching && selectedPat.data && (
        <CreateChangeSidepane
          isOpen={showCreateChangeSidepane}
          onClose={() => setShowCreateChangeSidepane(false)}
          pat={selectedPat.data}
        />
      )}
    </div>
  );
};

const SourceColumn = (row: unknown) => {
  const pat = row as SuperficialPat;
  const sourceName = pat.sourceNode.split('.').pop() || '';
  let icon;
  switch (pat.type) {
    case ChangeType.NewAggregateTable:
      icon = <DbtCloudIcon width="16" height="16" color="#FF694A" />;
      break;
    default:
      icon = <LookerIcon width="16" height="16" />;
      break;
  }
  return (
    <div className="flex gap-1 text-black" data-tag="allowRowEvents">
      <div>{icon}</div>
      <TextWithEllipsisAndTooltip text={sourceName} maxChars={20} />
    </div>
  );
};

const tableColumns = [
  {
    name: 'TYPE',
    selector: (row: unknown) => (
      <div className="flex gap-1 uppercase" data-tag="allowRowEvents">
        <div><SparklesIcon width="16" height="16" /></div><div>{(row as SuperficialPat).type}</div>
      </div>
    ),
    width: '200px'
  },
  {
    name: 'TITLE',
    selector: (row: unknown) => <span className="text-text-primary" data-tag="allowRowEvents"><TextWithEllipsisAndTooltip text={(row as SuperficialPat).title} maxChars={100} /></span>,
    width: 'auto'
  },
  {
    name: 'SOURCE',
    selector: SourceColumn,
    width: '250px'
  },
  {
    name: 'DATE',
    selector: (row: unknown) => unixToLocal((row as SuperficialPat).date, dateFormats.dateHoursAndMinutes),
    width: '200px'
  }
];

const createEqlFromFilters = (filterValues: Record<string, FilterValue>, searchQuery: string) => {
  const eqlFilters: string[] = [];
  if (searchQuery) {
    eqlFilters.push(`name ~ '${searchQuery}'`);
  }
  for (const key in filterValues) {
    const value = filterValues[key];
    const { start, end } = dateFilterToTimeRange((value || null) as DateFilterValue, false);
    switch (key) {
      case DataModelFilter.usage14d:
        eqlFilters.push(mapUsageToEqlFilter14d(value));
        break;
      case DataModelFilter.usage30d:
        eqlFilters.push(mapUsageToEqlFilter30d(value));
        break;
      case DataModelFilter.usage60d:
        eqlFilters.push(mapUsageToEqlFilter60d(value));
        break;
      case DataModelFilter.owner:
        eqlFilters.push(`shift_left_owner = '${value}'`);
        break;
      case DataModelFilter.createdAt:
        if (start && end) {
          eqlFilters.push(`shift_left_date BETWEEN ${start} AND ${end}`);
        }
        break;
      case DataModelFilter.type:
        eqlFilters.push(`(shift_left_type IN (${value?.split(',').map(v => `'${mapLocalChangeTypeToBackendChangeType.get(v as ChangeType)}'`).join(',')}))`);
        break;
    }
  }
  const filterString = eqlFilters.join(' AND ');
  return filterString;
};


const mapUsageToEqlFilter14d = (usage: FilterValue) => {
  switch (usage) {
    case '0':
      return 'total_queries_14d = 0';
    case '1-100':
      return 'total_queries_14d BETWEEN 1 AND 100';
    case '101-500':
      return 'total_queries_14d BETWEEN 101 AND 500';
    case '501-1000':
      return 'total_queries_14d BETWEEN 501 AND 1000';
    case '1001-5000':
      return 'total_queries_14d BETWEEN 1001 AND 5000';
    case '5001-10000':
      return 'total_queries_14d BETWEEN 5001 AND 10000';
    case '10001+':
      return 'total_queries_14d > 10000';
    default:
      return '';
  }
};

const mapUsageToEqlFilter30d = (usage: FilterValue) => {
  switch (usage) {
    case '0':
      return 'total_queries_30d = 0';
    case '1-100':
      return 'total_queries_30d BETWEEN 1 AND 100';
    case '101-500':
      return 'total_queries_30d BETWEEN 101 AND 500';
    case '501-1000':
      return 'total_queries_30d BETWEEN 501 AND 1000';
    case '1001-5000':
      return 'total_queries_30d BETWEEN 1001 AND 5000';
    case '5001-10000':
      return 'total_queries_30d BETWEEN 5001 AND 10000';
    case '10001+':
      return 'total_queries_30d > 10000';
    default:
      return '';
  }
};

const mapUsageToEqlFilter60d = (usage: FilterValue) => {
  switch (usage) {
    case '0':
      return 'total_queries_60d = 0';
    case '1-100':
      return 'total_queries_60d BETWEEN 1 AND 100';
    case '101-500':
      return 'total_queries_60d BETWEEN 101 AND 500';
    case '501-1000':
      return 'total_queries_60d BETWEEN 501 AND 1000';
    case '1001-5000':
      return 'total_queries_60d BETWEEN 1001 AND 5000';
    case '5001-10000':
      return 'total_queries_60d BETWEEN 5001 AND 10000';
    case '10001+':
      return 'total_queries_60d > 10000';
    default:
      return '';
  }
};