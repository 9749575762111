import { useSelector } from 'react-redux';
import Modal from '../../components/Modal/Modal';
import { ButtonTypes } from '../../components/button/types';
import { selectActiveAccountId } from '../../infrastructure/state/slices/activeAccountSlice';
import { useGetAccountOperationQuery } from '../../services/operations';
import OperationStatusLabel from './OperationStatusLabel';
import OperationLogsContainer from './OperationLogsContainer';
import { dateFormats, utcToLocal } from '../../infrastructure/dateUtilities';

interface OperationLogsModalProps {
  isOpen: boolean;
  onClose: () => void;
  operationId: number | null;
}

const OperationLogsModal = ({ isOpen, onClose, operationId }: OperationLogsModalProps) => {
  const accountId = useSelector(selectActiveAccountId);
  const getOperation = useGetAccountOperationQuery(
    { accountId, operationId: operationId || 0 },
    { skip: !operationId }
  );
  const operation = getOperation.data;
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title="View Operation Logs"
      buttons={[{ type: ButtonTypes.primary, text: 'Done', onClick: onClose }]}
      maxWidth="max-w-2xl">
      <div className="flex items-center justify-between mb-4">
        <div className="text-text-primary">
          {operation?.name}
          <span className="ml-2 text-sm text-slate-500">{operation?.start_time && utcToLocal(operation.start_time, dateFormats.monthsDaysHoursAndMinutes)}</span>
        </div>
        <div>{operation && <OperationStatusLabel status={operation.status} />}</div>
      </div>
      <OperationLogsContainer operationId={operationId || 0} />
    </Modal>
  );
};
export default OperationLogsModal;
