interface WeekDaysProps {
  onChange: (day: string) => void;
  selected?: string[];
  className?: string;
}

export const WeekDays = ({ selected, onChange, className }: WeekDaysProps) => {
  const dayClassName = `flex justify-center items-center text-center cursor-pointer w-9 h-9 mr-1.5 border rounded-full ${className}`;
  const days: {[key: string]: string} = {Mon: 'M', Tue: 'T', Wed: 'W', Thu: 'T', Fri: 'F', Sat: 'S', Sun: 'S'};

  return (
    <div className='flex'>
      {Object.keys(days).map(day => (
        <div
          key={`select-${day}`}
          className={`${dayClassName} ${selected?.includes(day) ? 'bg-lilac-700 text-white' : ''}`}
          onClick={() => onChange(day)}>
          {days[day]}
        </div>
      ))}
    </div>
  );
};
