import { useCallback, useEffect, useMemo } from "react";
import { SavedTableConfigKeys, TableColumnProps } from "../types";
import { useSearchParams } from "react-router-dom";
import { useTableView } from "../useTableView";
import { SelectorColumn } from "./SelectorColumn";

type UseTableColumnSelectorProps<T> = {
    columns: TableColumnProps<T>[];
    autoSaveColumns?: boolean;
    tableName: string;
    skip?: boolean;
}

export const useTableColumnSelector = <T,>({ columns, autoSaveColumns, tableName, skip = false }: UseTableColumnSelectorProps<T>) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const tableView = useTableView({ tableName: tableName || '', skip: !autoSaveColumns });
    const selectedColumns = useMemo(() => getSortedSelectColumns(searchParams.get('columns'), columns), [columns, searchParams]);

    const updateColumns = useCallback((columns: TableColumnProps<T>[], save = true) => {
        const columnNames = columns.map(column => column.name).join(',');
        searchParams.set(SavedTableConfigKeys.columns, columnNames);
        setSearchParams(searchParams);
        if (save && autoSaveColumns) {
            const newView = { ...tableView.tableView, columns: columnNames.split(',') };
            tableView.saveTableView(newView);
        }
    }, [searchParams, setSearchParams, autoSaveColumns, tableView]);

    useEffect(() => {
        const { columns: savedColumns } = tableView.tableView || {};
        if (savedColumns && !skip && !searchParams.get('columns')) {
            const newColumns = savedColumns.map((name: string) => columns.find(c => c.name === name)).filter(Boolean) as TableColumnProps<unknown>[];
            updateColumns(newColumns, false);
        }
    }, [tableView.tableView, columns, updateColumns, skip, searchParams]);

    return {
        selectedColumns,
        isLoading: tableView.isLoading,
        columnSelector: <SelectorColumn tableName={tableName} columns={columns} updateColumns={updateColumns} selectedColumns={selectedColumns} />
    };
};

const getSortedSelectColumns = <T,>(columnsQueryString: string | null, columns: TableColumnProps<T>[]) => {
    if (columnsQueryString) {
        const columnNames = columnsQueryString.split(',');
        return columns.filter(c => columnNames.includes(c.name) || c.isNotRemovable).sort((a, b) => {
            const aIndex = columnNames.indexOf(a.name);
            const bIndex = columnNames.indexOf(b.name);
            return aIndex - bIndex;
        });
    }
    else {
        return columns;
    }
};
