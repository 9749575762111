import { SparklesLeftArrowIcon } from "../../../assets/images/icons/DelphiIcons";

export const ModelChangeSection = ({ children }: { children: JSX.Element }) => {
    return (
        <div className="rounded-lg border border-slate-200 bg-white p-4 text-secondary flex-1 flex flex-col gap-4">
            <div className="flex items-center gap-2"><SparklesLeftArrowIcon width="20" height="20" fill="#94a3b8" /> Model change</div>
            <div>
                {children}
            </div>
        </div>
    );
};