import { PlusCircleIcon } from "@heroicons/react/24/solid";
import { NodeType, SubnodeType, nodeNameMap } from "../../models/discover/INode";
import { NodeIcon } from "../../models/discover/NodeIcon";
import { PlusBoldIcon, SidePaneIcon } from "../../../assets/images/icons/DelphiIcons";
import { TextWithEllipsisAndTooltip } from "../../../components/TextWithEllipsisAndTooltip";
import { ChevronDoubleLeftIcon, ChevronDoubleRightIcon, EllipsisHorizontalIcon } from "@heroicons/react/24/outline";
import DropdownMenu from "src/components/DropdownMenu/DropdownMenu";
import { DropdownMenuItem } from "src/components/DropdownMenu/types";
import { useState } from "react";
import { ResourceSidepane } from "src/features/models/discover/resourceSidepane/ResourceSidepane";

type ImplicationNodeProps = {
    nodeName: string;
    subNodeName?: string;
    isNew: boolean;
    nodeType: NodeType;
    subNodeType?: SubnodeType;
    utl?: string;
}

export const ImplicationNode = ({ nodeName, subNodeName, isNew, nodeType, subNodeType, utl }: ImplicationNodeProps) => {
    const isNodeNew = isNew && !subNodeName;
    const [selectedResourceId, setSelectedResourceId] = useState<string | null>(null);

    const viewDownstreamDependencies = () => {
        window.open(`/data-model?view=table&Downstream%20dependents=${utl}`, '_blank');
    };

    const viewUpstreamDependencies = () => {
        window.open(`/data-model?view=table&Upstream%20dependencies=${utl}`, '_blank');
    };

    const menuItems: DropdownMenuItem[] = [
        {
            name: isNew ? 'Downstream dependencies' : 'Upstream dependencies',
            icon: isNew ? <ChevronDoubleRightIcon className="text-tertiary" width="14" height="14" /> : <ChevronDoubleLeftIcon className="text-tertiary" width="14" height="14" />,
            onClick: isNew ? viewDownstreamDependencies : viewUpstreamDependencies
        },
        {
            name: 'Open in side panel',
            icon: <SidePaneIcon className="text-tertiary" width="14" height="14" />,
            onClick: () => setSelectedResourceId(utl || '')
        }
    ];

    return (
        <div
            className={`
                border bg-white p-2 shadow-sm relative flex-1 h-fit
                ${isNodeNew ? 'rounded-b-lg rounded-r-lg border-primary' : 'rounded-lg border border-gray-300'}
            `}>
            {
                isNodeNew && (
                    <div className="absolute z-10 -ml-[15px] -mt-[15px] bg-white text-primary">
                        <PlusBoldIcon width="14" height="14" />
                    </div>
                )
            }
            <div>
                <div className="flex justify-between gap-2 items-center">
                    <div className="flex items-center gap-2 text-sm font-medium text-black">
                        <div><NodeIcon type={nodeType} iconSize={20} /></div>
                        <div className="flex flex-col gap-0.5">
                            <div><TextWithEllipsisAndTooltip text={nodeName} maxChars={30} /></div>
                            <div className="font-normal text-tertiary -mt-1 text-xs">{nodeNameMap.get(nodeType)}</div>
                        </div>
                    </div>
                    {
                        utl && (
                            <DropdownMenu items={menuItems} className="ml-auto">
                                <div className="bg-slate-50 hover:bg-slate-100 w-fit h-fit px-1 py-1.5 rounded border border-border cursor-pointer text-text-primary">
                                    <EllipsisHorizontalIcon width="14" height="14" />
                                </div>
                            </DropdownMenu>
                        )
                    }
                </div>
                {
                    subNodeName && subNodeType && (
                        <SubnodeColumn isNew={isNew} nodeType={nodeType} subNodeType={subNodeType} subNodeName={subNodeName} />
                    )
                }
            </div>
            {
                selectedResourceId && (
                    <ResourceSidepane resourceId={selectedResourceId || ''} onClose={() => setSelectedResourceId(null)} />
                )
            }
        </div>
    );
};

type SubnodeColumnProps = {
    isNew: boolean;
    nodeType: NodeType;
    subNodeType: SubnodeType;
    subNodeName: string;
}

const SubnodeColumn = ({ isNew, nodeType, subNodeType, subNodeName }: SubnodeColumnProps) => {
    return (
        <>
            {
                isNew ? (
                    <div className="relative mt-3 text-primary">
                        <div className="absolute z-10 -ml-[5px] -mt-[5px] bg-white">
                            <PlusCircleIcon width="14" height="14" />
                        </div>
                        <div
                            style={{ background: 'linear-gradient(270deg, rgba(0, 71, 255, 0.10) 30%, rgba(255, 255, 255, 0.10) 95%)' }}
                            className="flex items-center gap-2 rounded border border-primary px-3 py-[1px] text-sm font-medium overflow-hidden">
                            <div className="h-fit rounded-sm p-[2px] text-white">
                                <NodeIcon type={nodeType} subnodeType={subNodeType} />
                            </div>
                            <TextWithEllipsisAndTooltip text={subNodeName} maxChars={25} />
                        </div>
                    </div>
                ) : (
                    <div className="relative mt-3 text-tertiary">
                        <div className="flex items-center gap-2 rounded border border-slate-200 px-3 py-[1px] text-sm font-medium">
                            <div className="h-fit rounded-sm p-[2px] text-white">
                                <NodeIcon type={nodeType} subnodeType={subNodeType} />
                            </div>
                            <TextWithEllipsisAndTooltip text={subNodeName} maxChars={25} />
                        </div>
                    </div>
                )
            }
        </>
    );
};