import { NodeIcon } from '../../NodeIcon';
import { ISuperficialNode, nodeNameMap } from '../../INode';
import { TableColumnProps } from '../../../../../components/Table/types';
import { DescriptionColumn } from './DescriptionColumn';
import { SparklesLeftArrowIcon } from '../../../../../assets/images/icons/DelphiIcons';
import { dateFormats, formatSecondsToHoursAndMinutes, utcToLocal } from 'src/infrastructure/dateUtilities';
import { CellWithTooltipAndCopy } from 'src/components/Table/CellWithTooltipAndCopy';

export const discoverTableColumns: TableColumnProps<ISuperficialNode>[] = [
  {
    name: 'Type',
    property: 'type',
    selector: row => <div data-tag="allowRowEvents" className="flex gap-1 items-center"><NodeIcon type={row.type} databaseTechnology={row.databaseTechnology} /> {nodeNameMap.get(row.type)}</div>,
    width: '200px',
    isNotRemovable: true,
    sortBy: 'type',
    group: 'General'
  },
  {
    name: 'Name',
    property: 'name',
    selector: row => <CellWithTooltipAndCopy text={row.name} maxChars={40} />,
    grow: 10,
    isNotRemovable: true,
    sortBy: 'name',
    group: 'General'
  },
  {
    name: 'Description',
    property: 'description',
    selector: row => <DescriptionColumn row={row as ISuperficialNode} />,
    width: '300px',
    sortBy: 'description',
    group: 'General'
  },
  {
    name: 'Columns',
    property: 'number_of_columns',
    selector: row => printNumber(row.numberOfColumns),
    width: '110px',
    sortBy: 'number_of_columns',
    group: 'dbt'
  },
  {
    name: 'Dimensions',
    selector: row => printNumber(row.numberOfDimensions),
    width: '140px',
    property: 'number_of_dimensions',
    sortBy: 'number_of_dimensions',
    group: 'dbt'
  },
  {
    name: 'Measures',
    selector: row => printNumber(row.numberOfMeasures),
    width: '110px',
    property: 'number_of_measures',
    sortBy: 'number_of_measures',
    group: 'dbt'
  },
  {
    name: 'Entities',
    selector: row => printNumber(row.numberOfEntities),
    width: '110px',
    property: 'number_of_entities',
    sortBy: 'number_of_entities',
    group: 'dbt'
  },
  {
    name: 'Related Metrics',
    selector: row => printNumber(row.numberOfMetrics),
    width: '150px',
    property: 'number_of_metrics',
    sortBy: 'number_of_metrics',
    group: 'dbt'
  },
  {
    name: 'Custom Fields',
    selector: row => printNumber(row.numberOfCustomFields),
    width: '140px',
    property: 'number_of_custom_fields',
    sortBy: 'number_of_custom_fields',
    group: 'dbt'
  },
  {
    name: 'Is Trivial SQL',
    property: 'is_trivial_sql',
    selector: row => {
      const value = row.isTrivialSql;
      if (value === true) {
        return 'Yes';
      } else if (value === false) {
        return 'No';
      }
      else {
        return '';
      }
    },
    sortBy: 'is_trivial_sql',
    width: '150px',
    group: 'General'
  },
  {
    name: 'First seen',
    property: 'first_seen_at',
    selector: row => row.firstSeen && (new Date(row.firstSeen)).toISOString().split('T')[0],
    width: '150px',
    sortBy: 'first_seen_at',
    group: 'General'
  },
  {
    name: 'Materialized',
    property: 'materialized',
    selector: row => {
      if (row.materialized !== null) {
        return row.materialized ? 'True' : 'False';
      }
    },
    width: '150px',
    sortBy: 'materialized',
    group: 'General'
  },
  {
    name: 'dbt materialization strategy',
    property: 'dbt_materialization_strategy',
    selector: row => row.dbtMaterializationStrategy,
    width: '220px',
    sortBy: 'dbt_materialization_strategy',
    group: 'dbt'
  },
  {
    name: 'Tableau has extracts',
    property: 'tableau_has_extracts',
    selector: row => {
      if (row.tableauHasExtracts !== null) {
        return row.tableauHasExtracts ? 'True' : 'False';
      }
    },
    width: '180px',
    sortBy: 'tableau_has_extracts',
    group: 'Tableau'
  },
  {
    name: 'Extract last updated',
    property: 'native_last_data_update',
    selector: row => row.nativeLastDataUpdate && utcToLocal(row.nativeLastDataUpdate || '', dateFormats.dateHoursAndMinutes),
    width: '250px',
    sortBy: 'native_last_data_update',
    group: 'Tableau'
  },
  {
    name: 'Refresh frequency',
    property: 'tableau_extract_refresh_frequency',
    selector: row => row.refreshFrequency,
    width: '180px',
    sortBy: 'tableau_extract_refresh_frequency',
    group: 'Tableau'
  },
  {
    name: 'Tableau workbook',
    property: 'tableau_workbook',
    selector: row => row.tableauWorkbook,
    width: '200px',
    sortBy: 'tableau_workbook',
    group: 'Tableau'
  },
  {
    name: 'Tableau project',
    property: 'tableau_project',
    selector: row => row.tableauProject,
    width: '200px',
    sortBy: 'tableau_project',
    group: 'Tableau'
  },
  {
    name: 'Tags',
    property: 'tags',
    selector: row => row.tags?.join(', '),
    sortBy: 'tags',
    group: 'dbt'
  },
  {
    name: 'dbt project',
    property: 'dbt_project',
    selector: row => row.dbtProject,
    width: '150px',
    sortBy: 'dbt_project',
    group: 'dbt'
  },
  {
    name: 'Database schema',
    property: 'database_schema',
    selector: row => row.databaseSchema,
    width: '200px',
    sortBy: 'database_schema',
    group: 'General'
  },
  {
    name: 'Database',
    property: 'database',
    selector: row => row.database,
    sortBy: 'database',
    width: '150px',
    group: 'General'
  },
  {
    name: 'Looker folder',
    property: 'looker_folder',
    selector: row => row.lookerFolder,
    width: '150px',
    sortBy: 'looker_folder',
    group: 'Looker'
  },
  {
    name: 'Looker model',
    property: 'looker_model',
    selector: row => row.lookerModel,
    width: '150px',
    sortBy: 'looker_model',
    group: 'Looker'
  },
  {
    name: 'Looker instance',
    property: 'looker_host',
    selector: row => row.lookerHost,
    width: '150px',
    sortBy: 'looker_host',
    group: 'Looker'
  },
  {
    name: 'Source directory',
    property: 'source_directory',
    selector: row => row.sourceDirectory,
    width: '150px',
    sortBy: 'source_directory',
    group: 'General'
  },
  {
    name: 'UTL',
    property: 'uri',
    selector: row => <CellWithTooltipAndCopy text={row.id} maxChars={100} />,
    width: '700px',
    sortBy: 'uri',
    group: 'General'
  },
  {
    name: 'Owner',
    property: 'owner',
    selector: row => row.owner,
    width: '200px',
    sortBy: 'owner',
    group: 'General'
  },
  {
    name: 'Total views 30 days',
    selector: row => printNumber(row.totalViews30Days),
    width: '210px',
    property: 'last_30d_views',
    sortBy: 'last_30d_views',
    group: 'Usage'
  },
  {
    name: 'Total views 7 days',
    selector: row => printNumber(row.totalViews7Days),
    width: '210px',
    property: 'last_7d_views',
    sortBy: 'last_7d_views',
    group: 'Usage'
  },
  {
    name: 'Total queries 14 days',
    selector: row => printNumber(row.totalQueries14Days),
    width: '210px',
    property: 'total_queries_14d',
    sortBy: 'total_queries_14d',
    group: 'Usage'
  },
  {
    name: 'Total queries 30 days',
    selector: row => printNumber(row.totalQueries30Days),
    width: '210px',
    property: 'total_queries_30d',
    sortBy: 'total_queries_30d',
    group: 'Usage'
  },
  {
    name: 'Total queries 60 days',
    selector: row => printNumber(row.totalQueries60Days),
    width: '210px',
    property: 'total_queries_60d',
    sortBy: 'total_queries_60d',
    group: 'Usage'
  },
  {
    name: 'Total impressions 14 days',
    selector: row => printNumber(row.totalImpressions14Days),
    width: '210px',
    property: 'total_impressions_14d',
    sortBy: 'total_impressions_14d',
    group: 'Usage'
  },
  {
    name: 'Total impressions 30 days',
    selector: row => printNumber(row.totalImpressions30Days),
    width: '210px',
    property: 'total_impressions_30d',
    sortBy: 'total_impressions_30d',
    group: 'Usage'
  },
  {
    name: 'Total impressions 60 days',
    selector: row => printNumber(row.totalImpressions60Days),
    width: '210px',
    property: 'total_impressions_60d',
    sortBy: 'total_impressions_60d',
    group: 'Usage'
  },
  {
    name: 'Distinct user impressions 14 days',
    selector: row => printNumber(row.distinctUserImpressions14Days),
    width: '250px',
    property: 'distinct_impressions_users_14d',
    sortBy: 'distinct_impressions_users_14d',
    group: 'Usage'
  },
  {
    name: 'Distinct user impressions 30 days',
    selector: row => printNumber(row.distinctUserImpressions30Days),
    width: '250px',
    property: 'distinct_impressions_users_30d',
    sortBy: 'distinct_impressions_users_30d',
    group: 'Usage'
  },
  {
    name: 'Distinct user impressions 60 days',
    selector: row => printNumber(row.distinctUserImpressions60Days),
    width: '250px',
    property: 'distinct_impressions_users_60d',
    sortBy: 'distinct_impressions_users_60d',
    group: 'Usage'
  },
  {
    name: 'Distinct users 14 days',
    selector: row => printNumber(row.distinctUsers14Days),
    width: '210px',
    property: 'distinct_users_14d',
    sortBy: 'distinct_users_14d',
    group: 'Usage'
  },
  {
    name: 'Distinct users 30 days',
    selector: row => printNumber(row.distinctUsers30Days),
    width: '210px',
    property: 'distinct_users_30d',
    sortBy: 'distinct_users_30d',
    group: 'Usage'
  },
  {
    name: 'Distinct users 60 days',
    selector: row => printNumber(row.distinctUsers60Days),
    width: '210px',
    property: 'distinct_users_60d',
    sortBy: 'distinct_users_60d',
    group: 'Usage'
  },
  {
    name: 'Is identity',
    selector: row => {
      const value = row.isIdentityTransformation;
      if (value === true) {
        return 'Yes';
      } else if (value === false) {
        return 'No';
      }
      else {
        return '';
      }
    },
    width: '150px',
    property: 'is_identity_transformation',
    sortBy: 'is_identity_transformation',
    group: 'General'
  },
  {
    name: 'PDT trigger',
    selector: row => row.lookmlViewPersistency,
    width: '150px',
    property: 'lookml_view_persistency',
    sortBy: 'lookml_view_persistency',
    group: 'Looker'
  },
  {
    name: 'PDT builds 30d',
    selector: row => printNumber(row.pdtBuildsLast30d),
    width: '150px',
    property: 'pdt_builds_last_30d',
    sortBy: 'pdt_builds_last_30d',
    group: 'Looker'   
  },
  {
    name: 'Total PDT build time 30d',
    selector: row => {
      const value = row.pdtTotalBuildTime30d;
      if (value) {
        return formatSecondsToHoursAndMinutes(value);
      }
      else {
        return '';
      }
    },
    width: '200px',
    property: 'pdt_total_build_time_30d',
    sortBy: 'pdt_total_build_time_30d',
    group: 'Looker'
  },
  {
    name: 'Derived table type',
    selector: row => row.derivedType,
    width: '200px',
    property: 'derived_type',
    sortBy: 'derived_type',
    group: 'Looker'
  },
  {
    name: 'Source path',
    selector: row => row.sourcePath,
    width: '150px',
    property: 'source_path',
    sortBy: 'source_path',
    group: 'General'
  },
  {
    name: 'Refinements',
    selector: row => {
      const hasRefinements = row.hasRefinements;
      if (hasRefinements === null) {
        return '';
      }
      else if (hasRefinements) {
        return 'Includes refinements';
      }
      else {
        return 'Does not include refinements';
      }
    },
    width: '150px',
    property: 'has_refinements',
    sortBy: 'has_refinements',
    group: 'Looker'
  },
];

export const subResourcesDiscoverColumns: TableColumnProps<ISuperficialNode>[] = [
  {
    name: 'Parent',
    property: 'parent_name',
    selector: row => <div data-tag="allowRowEvents" className="flex gap-1 items-center">{row.parentName}</div>,
    width: '200px',
    isNotRemovable: true,
    group: 'General'
  },
  {
    name: 'Is calculated',
    property: 'is_calculated',
    selector: row => {
      if (row.isCalculated !== null) {
        return row.isCalculated ? 'True' : 'False';
      }
    },
    width: '150px',
    sortBy: 'is_calculated',
    group: 'General'
  },
];

const printNumber = (value?: number | null) => typeof value === 'number' ? `${Math.round(value)}` : '';

export const proposalsColumn: TableColumnProps<ISuperficialNode> = {
  name: 'Proposals',
  property: 'has_shift_left_potential',
  selector: row => row.hasProposals ? <SparklesLeftArrowIcon width="16" height="16" className='text-primary' data-tag="allowRowEvents" /> : '',
  sortBy: 'has_shift_left_potential',
  width: '120px',
  group: 'General'
};