import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from 'react-redux';
import { selectActiveAccountId } from "src/infrastructure/state/slices/activeAccountSlice";
import { useUpdateGenericIntegrationMutation, useGetGenericIntegrationsQuery, useCrawlIntegrationMutation }
  from "src/services/integrations/integrations";
import { notify } from 'src/components/Toaster';
import { HealthLabel, HealthType } from "src/components/HealthLabel";
import { SOURCE_ICONS } from "src/features/sources/Consts";
import { TextWithEllipsisAndTooltip } from "src/components/TextWithEllipsisAndTooltip";
import { Search } from "src/components/Search";
import { EllipsisVerticalIcon } from "@heroicons/react/24/solid";
import DropdownMenu from "src/components/DropdownMenu/DropdownMenu";
import { GenericIntegration } from "src/services/integrations/types";
import { extractErrorMessage } from "src/services/api";
import PageLoader from "src/components/loaders/PageLoader";
import EmptyEnabledSources, { EnabledSourcesType } from "src/features/sources/EmptyEnabledSources";
import { IntegrationInfoModal } from "src/features/sources/IntegrationInfoModal";
import { SourceRunStatus } from './details/SourceRunStatus';


const EnabledSources = ({ setTab }: EnabledSourcesType) => {
  const navigate = useNavigate();
  const accountId = useSelector(selectActiveAccountId);
  const { data: integrations = [], isLoading } = useGetGenericIntegrationsQuery({ accountId });
  const [updateIntegration] = useUpdateGenericIntegrationMutation();
  const [crawlIntegration] = useCrawlIntegrationMutation();
  const [runUrl, setRunUrl] = useState('');
  const [search, setSearch] = useState('');
  const [activeStatusLoading, setActiveStatusLoading] = useState<number | null>(null);
  const [runLoading, setRunLoading] = useState<number | null>(null);

  if (isLoading) {
    return (<PageLoader />);
  }

  if (integrations.length === 0) {
    return (<EmptyEnabledSources setTab={setTab} />);
  }

  const menuOptions = (integration: GenericIntegration) => [
    {
      name: integration.active ? 'Pause' : 'Resume', className: 'hover:bg-slate-50',
      onClick: async () => {
        setActiveStatusLoading(integration.id);
        try {
          await updateIntegration({
            accountId,
            integrationId: integration.id,
            integrationName: integration.name,
            integrationType: integration.integration_type,
            active: !integration.active
          }).unwrap();
        } catch (e) {
          const errorMessage = `Failed to pause/resume integration: ${extractErrorMessage(e).message}`;
          notify(errorMessage, 'error');
          console.error(errorMessage);
        }
        setActiveStatusLoading(null);
      }
    },
    {
      name: 'Edit', className: 'hover:bg-slate-50',
      onClick: () => {
        navigate(`/sources/connect/${integration.integration_type}/${integration.id}`);
      }
    },
    {
      name: 'Run now', className: 'hover:bg-slate-50 !text-surface-primary',
      onClick: async () => {
        if (integration.integration_type === 'dbt_core') {
          setRunUrl(`/accounts/${accountId}/integrations/${integration.id}/run`);
        } else {
          setRunLoading(integration.id);
          try {
            await crawlIntegration({ accountId, integrationId: integration.id }).unwrap();
          } catch (e) {
            const errorMessage = `Failed to run integration: ${extractErrorMessage(e).message}`;
            notify(errorMessage, 'error');
            console.error(errorMessage);
          }
          setRunLoading(null);
        }
      }
    }
  ];

  const sortedIntegrations = integrations.filter(i => i.name.includes(search)).sort((a, b) => b.id - a.id);
  return (
    <>
      <div className='w-[50rem] mx-auto mt-8'>
        <Search
          search={search}
          setSearch={setSearch}
          className="ml-auto w-fit"
          placeholder="Search sources"
        />
      </div>
      {
        search && sortedIntegrations.length === 0 && (
          <div className='w-[50rem] h-40 mt-9 border flex flex-col m-auto rounded-lg items-center'>
            <div className='text-slate-400 my-auto'>
              No sources found for your search
            </div>
          </div>
        )
      }
      {sortedIntegrations.map(integration => {
        const SourceIcon = SOURCE_ICONS[integration.integration_type];
        return (
          <div
            key={integration.id}
            className='w-[50rem] h-14 mt-2 border flex cursor-pointer items-center m-auto rounded-lg bg-white relative'
            onClick={() => navigate(`/sources/${integration.id}`)}
          >
            <SourceIcon width="40" height="40" className="mx-5 text-black" fill="#FF694A" />
            <TextWithEllipsisAndTooltip maxChars={30} text={integration.name} />
            <div className="absolute left-80 flex items-center">
              <HealthLabel health={integration.health as HealthType} className={`${runLoading === integration.id ? 'opacity-50' : ''}`} />
              {integration.last_run_status === 'running' && <span className="text-tertiary ml-4 mr-1">Running...</span>}
              {integration.last_run_status !== 'running' && integration.last_completed_run_end_time && (
                <>
                  <span className="text-tertiary ml-4 mr-1">Last run</span>
                  <span className="text-secondary">{integration.last_completed_run_end_time}</span>
                </>
              )}
            </div>
            <div className="absolute right-0 flex items-center">
              <SourceRunStatus active={integration.active} loading={activeStatusLoading === integration.id} />
              <DropdownMenu items={menuOptions(integration)} className="bg-white" withStopPropogation>
                <EllipsisVerticalIcon className="text-slate-400 mr-6 ml-4" width={20} height={20} />
              </DropdownMenu>
            </div>
          </div>
        );
      })}
      <IntegrationInfoModal
        info={runUrl}
        modalTitle="Run URL"
        infoTitle="Use this url to trigger your run"
        label="Your run url"
        onClose={() => setRunUrl('')}
      />
    </>
  );
};

export default EnabledSources;
