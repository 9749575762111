import Layout from 'src/components/layout/Layout';
import TopBar from 'src/components/layout/TopBar';
import { PageTabs } from 'src/components/PageTabs';
import Gallery from './Gallery';
import EnabledSources from './EnabledSources';
import { useState } from 'react';


const Sources = () => {
  const [tab, setTab] = useState<'enabled' | 'gallery'>('enabled');

  return (
    <Layout>
      <TopBar />
      <div className="flex flex-col bg-white border-b">
        <div className="w-[50rem] h-40 flex flex-col m-auto">
          <div className="text-2xl mt-10">Sources</div>
          <div className="text-tertiary mt-1 mb-auto">Add and manage connected sources and set up their schedule.</div>
          <PageTabs
            withUnderscore
            tabs={[
              { name: 'Enabled', isActive: tab === 'enabled', onClick: () => setTab('enabled') },
              { name: 'Gallery', isActive: tab === 'gallery', onClick: () => setTab('gallery') }
            ]}
          />
        </div>
      </div>
      <div>
        {
          tab === 'enabled' ? (
            <EnabledSources setTab={setTab}/>
          ) : (
            <Gallery />
          )
        }
      </div>
    </Layout>
  );
};

export default Sources;
