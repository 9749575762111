import { ButtonTypes } from 'src/components/button/types';
import Modal from 'src/components/Modal/Modal';
import { Operation } from "src/features/operations/Operation";
import { dateFormats, utcToLocal } from 'src/infrastructure/dateUtilities';

interface LogsModalProps {
  isOpen: boolean;
  report: object
  onClose: () => void;
}

export const LogsModalContent = ({ operation }: { operation: Operation }) => {

  const contentMap = {
    ID: operation.id,
    Name: operation.name,
    'Start Time': utcToLocal(operation.start_time, dateFormats.monthsDaysHoursAndMinutes),
    'End Time': operation.end_time ? utcToLocal(operation.end_time, dateFormats.monthsDaysHoursAndMinutes) : '',
    'Project ID': operation.project_id,
    'Observer ID': operation.observer_id,
    'Observer Type': operation.observer_type,
    Status: operation.status,
    Trigger: operation.trigger
  };

  return (
    <div className="mt-4 max-h-64 overflow-auto whitespace-pre-line rounded-lg border border-slate-200 bg-surface-light
      p-2 text-sm text-text-primary">
      {Object.entries(contentMap).map(([label, value]) => (
        <div className="text-text-primary text-base" key={label}>
          {label}:
          <span className="ml-2 text-sm text-slate-500">{value}</span>
        </div>
      ))}
    </div>
  );
};

export const OperationModal = ({ isOpen, report, onClose }: LogsModalProps) => {
  const textualReport: { text: JSX.Element, indent: number }[] = [];
  addKeyValuePairs(report, textualReport, 0);

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title="Run report"
      buttons={[{ type: ButtonTypes.primary, text: 'Done', onClick: onClose }]}
      maxWidth="max-w-2xl"
    >
      <div className="flex flex-col gap-2">
        {
          textualReport.map((item, index) => (
            <div key={index} style={{marginLeft: item.indent * 20}}>
              {item.text}
            </div>
          ))
        }
      </div>
    </Modal>
  );
};

function addKeyValuePairs(report: object, textualReport: { text: JSX.Element, indent: number }[], indent: number) {
  for (const [key, value] of Object.entries(report)) {
    if (value && typeof value === 'object' && !Array.isArray(value)) {
      textualReport.push({ text: <span className="font-semibold">{key}</span>, indent });
      addKeyValuePairs(value, textualReport, indent + 1);
    } else {
      textualReport.push({ text: <div className="flex gap-2"><span className="font-semibold">{key}</span><span>{value}</span></div>, indent });
    }
  }
}