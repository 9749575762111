import { ModelIcon } from '../../../../assets/images/icons/DelphiIcons';
import { CheckCircleIcon } from '@heroicons/react/24/solid';
import { IChange, NewColumnChangeData } from '../../IChange';
import { MetaSection } from '../MetaSection';
import { TagsSection } from '../TagsSection';
import { NodeType, SubnodeType } from '../../../models/discover/INode';
import { NodeIcon } from '../../../models/discover/NodeIcon';
import { TextWithEllipsisAndTooltip } from '../../../../components/TextWithEllipsisAndTooltip';
import { ModelChangeSection } from '../ModelChangeSection';
import { ModelChangeImplications } from '../../ModelChangeImplications';

export const ChangeSection = ({ change }: { change: IChange }) => {
  const newColumnChange = change.changeData as NewColumnChangeData;
  return (
    <div className="flex flex-col gap-8">
      <div>
        <div className="flex gap-2">
          <div className="bg-white p-2 rounded-lg border border-slate-200 h-fit w-fit mt-0.5">
            <NodeIcon type={NodeType.DataModel} subnodeType={SubnodeType.Column} iconSize={20} />
          </div>
          <div className="flex flex-col">
            <div className="text-lg text-text-primary">{newColumnChange.newColumnName}</div>
            <div className="flex items-center text-sm text-tertiary">
              Column in <ModelIcon width="14" height="14" fill="#64748B" className="ml-2 mr-1" />
              <span className="font-medium">{newColumnChange.modelId}</span>
            </div>
          </div>
        </div>
        <div className="mt-4 text-text-primary">{newColumnChange.description}</div>
      </div>
      <div className="flex gap-4">
        <div className="rounded-lg border border-slate-200 bg-white p-4 text-secondary w-[30%]">
          <TagsSection tags={newColumnChange.tags} />
          <MetaSection meta={newColumnChange.meta} />
          <TestsSection change={change} />
        </div>
        <ModelChangeSection>
          <ModelChangeImplications targetUtl={change.targetUtl} sourceUtl={change.sourceUtl} targetNodeName={newColumnChange.modelId} targetSubnodeName={newColumnChange.newColumnName} targetSubnodeType={SubnodeType.Column} sourceName={change.sourceName} sourceType={change.sourceType} sourceSubnodeName={newColumnChange.customFieldInDataApplication} sourceSubnodeType={SubnodeType.Dimension} />
        </ModelChangeSection>
      </div>
    </div>
  );
};

const TestsSection = ({ change }: { change: IChange }) => {
  const tests: string[] = [];
  const newColumnChange = change.changeData as NewColumnChangeData;
  if (newColumnChange.isUnique) {
    tests.push('Unique');
  }
  if (!newColumnChange.isNullable) {
    tests.push('Not Null');
  }
  if (newColumnChange.acceptedValues.length > 0) {
    tests.push(`${newColumnChange.acceptedValues.length} accepted values`);
  }
  if (newColumnChange.relationships) {
    tests.push(`Relationships: ${newColumnChange.relationships.model}:${newColumnChange.relationships.dimension}`);
  }
  const withTests = tests.length > 0;
  return (
    <div>
      <div className="mt-3 flex items-center gap-1">
        <CheckCircleIcon width="16" height="16" />
        Tests
      </div>
      <div className="mt-2 text-sm">
        {
          withTests ? (
            tests.map((test, index) => (
              <TextWithEllipsisAndTooltip key={index} text={test} maxChars={40} />
            ))
          ) : 'No tests'
        }
      </div>
    </div>
  );
};