export function formatNumber(value: number): string{
    if (isNaN(value) || value === null || value === undefined) {
        return '0';
    }

    const isNegative = value < 0;
    const absValue = Math.abs(value);

    const formatWithSuffix = (divisor: number, suffix: string): string =>
        `${isNegative ? '-' : ''}${(absValue / divisor).toFixed(1)}${suffix}`;

    if (absValue >= 1_000_000_000) {
        return formatWithSuffix(1_000_000_000, 'B'); // Billions
    }

    if (absValue >= 1_000_000) {
        return formatWithSuffix(1_000_000, 'M'); // Millions
    }

    const addCommas = (num: number): string =>
        num.toLocaleString('en-US', { maximumFractionDigits: 2 });

    return `${isNegative ? '-' : ''}${addCommas(absValue)}`;
}