import { useNavigate } from 'react-router-dom';
import Button from "src/components/button/Button";
import { ButtonTypes } from "src/components/button/types";
import { PlayIcon, SpinnerIcon } from "src/assets/images/icons/DelphiIcons";
import { GenericIntegration, GenericIntegrationConfiguration } from "src/services/integrations/types";
import { notify } from "src/components/Toaster";
import { extractErrorMessage } from "src/services/api";
import { useSelector } from "react-redux";
import { selectActiveAccountId } from "src/infrastructure/state/slices/activeAccountSlice";
import {
  useCreateGenericIntegrationMutation,
  useTestIntegrationConfigurationMutation,
  useUpdateGenericIntegrationMutation
} from "src/services/integrations/integrations";
import { useState } from "react";
import { FIELDS_BY_TYPE } from "src/features/sources/connectedSources/ConnectedSourcesConsts";
import Checkbox from "src/components/form/Checkbox";
import Modal from 'src/components/Modal/Modal';
import { ClipboardDocumentIcon } from '@heroicons/react/24/outline';
import { Alert } from 'src/components/Alert';


type BottomBarType = {
  integration: GenericIntegration,
}

const BottomBar = ({ integration }: BottomBarType) => {
  const navigate = useNavigate();
  const accountId = useSelector(selectActiveAccountId);
  const [testIntegrationConfigurationMutation, { isLoading: isLoadingTest }] = useTestIntegrationConfigurationMutation();
  const [secretToCopy, setSecretToCopy] = useState('');
  const [createIntegration, { isLoading: isLoadingCreate }] = useCreateGenericIntegrationMutation();
  const [updateIntegration, { isLoading: isLoadingUpdate }] = useUpdateGenericIntegrationMutation();

  const cleanEmptyMappings = (configuration: GenericIntegrationConfiguration) => {
    const cleanConfiguration = { ...configuration };
    for (const key of Object.keys(cleanConfiguration)) {
      const configurationKey = key as keyof GenericIntegrationConfiguration;
      if (cleanConfiguration[configurationKey] && typeof cleanConfiguration[configurationKey] === 'object') {
        delete cleanConfiguration[configurationKey][''];
      }
    }
    return cleanConfiguration;
  };

  const update = async () => {
    try {
      if (!integration) {
        return;
      }
      await updateIntegration({
        accountId,
        integrationId: integration.id,
        integrationName: integration.name,
        integrationType: integration.integration_type,
        configuration: cleanEmptyMappings(integration.configuration),
        schedule: integration.schedule
      }).unwrap();
      navigate('/sources');
      notify('Source updated successfully', 'success');
    } catch (e) {
      console.error(e);
      notify(`Error updating integration: ${extractErrorMessage(e).message}`, 'error');
    }
  };

  const create = async () => {
    try {
      const integrationData = await createIntegration({
        accountId,
        integrationName: integration.name,
        configuration: cleanEmptyMappings(integration.configuration),
        schedule: integration.schedule,
        integrationType: integration.integration_type
      }).unwrap();
      if (integration.integration_type === "dbt_core") {
        setSecretToCopy(integrationData.trigger_secret || '');
      } else {
        navigate('/sources');
        notify('Source created successfully', 'success');
      }
    } catch (e) {
      notify(`Error creating integration: ${extractErrorMessage(e).message}`, 'error');
    }
  };

  const test = async () => {
    try {
      FIELDS_BY_TYPE[integration.integration_type].forEach((field) => {
        if (!field['optional'] && field['component'] !== Checkbox) {
          if (!integration.configuration || !integration.configuration[field['title'] as keyof GenericIntegrationConfiguration])
            throw new Error(`${field['label']} is a mandatory field`);
        }
      });
      if (integration.configuration) {
        await testIntegrationConfigurationMutation({ configuration: cleanEmptyMappings(integration.configuration), integrationType: integration.integration_type }).unwrap();
        notify('Your configuration is correct', 'success');
      }
    } catch (e) {
      notify(`${extractErrorMessage(e).message}`, 'error');
    }
  };

  const onCloseDbtKeyModal = () => {
    setSecretToCopy('');
    navigate('/sources');
  };

  const copySecret = () => {
    navigator.clipboard.writeText(secretToCopy);
    notify('Copied to clipboard', 'success');
  };

  return (
    <div className="flex gap-4 bg-white px-8 py-3 border-t border-border sticky bottom-0 w-full">
      <Button
        type={ButtonTypes.secondary}
        text="Cancel"
        className="w-24"
        onClick={() => navigate('/sources')}
      />
      <Button
        type={ButtonTypes.secondary}
        text="Test"
        className="ml-auto w-24"
        icon={isLoadingTest ? <SpinnerIcon className='animate-spin' /> : <PlayIcon width="16" height="16" />}
        onClick={test}
        isLoading={isLoadingTest}
        isLoadingText="Testing..."
      />
      <Button
        type={ButtonTypes.primary}
        className="w-24"
        text={"Save"}
        onClick={integration.id ? update : create}
        isLoading={isLoadingCreate || isLoadingUpdate}
        isLoadingText="Saving..."
      />
      {integration.integration_type === "dbt_core" && (
        <Modal
          isOpen={!!secretToCopy}
          onClose={onCloseDbtKeyModal}
          title="Integration key"
          buttons={[{ type: ButtonTypes.primary, text: 'Done', onClick: onCloseDbtKeyModal }]}
          maxWidth="max-w-2xl"
        >
          <div className="flex flex-col text-center mt-2">
            <div className="text-text-primary text-2xl">Integration key created successfully</div>
            <div className="text-tertiary">You`ll need this key to setup your system. </div>
            <div className="flex flex-row justify-between bg-slate-50 border border-slate-200 p-4 my-4 rounded-lg">
              <div className="text-secondary">Key</div>
              <div className="text-slate-500">{secretToCopy}</div>
              <div className="flex items-center cursor-pointer select-none" onClick={copySecret}>
                <ClipboardDocumentIcon width={12} height={12} className="text-lilac-600" />
                <span className="text-lilac-600 ml-1">Copy</span>
              </div>
            </div>
            <Alert
              className="!border-0 rounded-lg mb-4"
              title="Attention:"
              text="The key will not be visible after its creation."
              type="warning"
            />
          </div>
        </Modal>
      )}
    </div>
  );
};

export default BottomBar;
